import React, { useCallback } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import StyleIcon from "@mui/icons-material/Style";
import styled from "@emotion/styled";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "8px 12px",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    transition: "opacity 0.2s",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.9)",
    },
  },
  "& .MuiTooltip-arrow": {
    color: "rgba(0, 0, 0, 0.8)",
  },
}));

export default function LeftSideBarIcon({ open, name, Icon }) {
  const handleClick = useCallback(() => {
    if (name === "Support" && window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  }, [name]);

  const buttonContent = (
    <>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : "auto",
          justifyContent: "center",
          color: "#fff",
          '& svg': {
            fontSize: '1.8rem',
            transition: 'transform 0.2s ease-in-out',
          },
        }}
      >
        <Icon className='text-3xl' />
      </ListItemIcon>
      <ListItemText
        primary={`${name}`}
        sx={{
          opacity: open ? 1 : 0,
          "& .MuiTypography-root": {
            color: "#fff",
            fontWeight: "bold",
            fontSize: '1.1rem',
            letterSpacing: '0.5px',
          },
        }}
      />
    </>
  );

  return (
    <ListItem
      key={`${name}`}
      disablePadding
      sx={{ 
        display: "block",
        '&:hover': {
          '& .MuiListItemButton-root': {
            background: 'linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
            '& svg': {
              transform: 'scale(1.1)',
            },
          },
        },
      }}
    >
      {!open ? (
        <CustomTooltip title={`${name}`} placement="right">
          <ListItemButton
            onClick={handleClick}
            sx={{
              minHeight: 52,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
              borderRadius: '8px',
              mx: '6px',
              transition: 'all 0.2s ease-in-out',
            }}
          >
            {buttonContent}
          </ListItemButton>
        </CustomTooltip>
      ) : (
        <ListItemButton
          onClick={handleClick}
          sx={{
            minHeight: 52,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
            borderRadius: '8px',
            mx: '6px',
            transition: 'all 0.2s ease-in-out',
          }}
        >
          {buttonContent}
        </ListItemButton>
      )}
    </ListItem>
  );
}