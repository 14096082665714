import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Paper, Grid, Divider, Container } from '@mui/material';
import LevelUpBar from '../LevelUpBar.tsx';
import { useProfile } from '../../contexts/ProfileContext.tsx';
import { GoldCoin } from '../Coins.tsx';
import { supabase } from "../../api/supabaseClient.js";
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext.js';
import { useBalance } from '../../contexts/BalanceContext.js';
import DailyChallenge from './DailyChallenge';
import LoadingScreen from '../LoadingScreen';

interface Reward {
    reward_key: string;
    reward_claimed: boolean;
}

interface Challenge {
    id: number;
    description: string;
    minimum_bet: number;
    reward: number;
    expiry: string;
    completed: boolean;
    completed_by: string | null;
}

const rewardMap: { [key: string]: { description: string, value: number | string } } = {
    "early_adopter": { description: "10 Gold Tokens and Permanent 2.5% increased rakeback", value: 10 },
    "discord_verified": { description: "5 Gold Tokens and Permanent 2.5% increased rakeback", value: 0 },
    "0": { description: "5% Rakeback", value: "5%" },
    "1": { description: "7.5% Rakeback", value: "7.5%" },
    "2": { description: "10% Rakeback", value: "10%" },
    "3": { description: "12.5% Rakeback", value: "12.5%" },
    "4": { description: "15% Rakeback", value: "15%" },
    "5": { description: "17.5% Rakeback", value: "17.5%" },
    "6": { description: "20% Rakeback", value: "20%" },
    "7": { description: "22.5% Rakeback", value: "22.5%" },
    "8": { description: "25% Rakeback", value: "25%" },
    "9": { description: "27.5% Rakeback", value: "27.5%" },
    "10": { description: "30% Rakeback", value: "30%" },
};

const goldTokenMap: { [key: string]: number } = {
    "1": 2.5, "2": 10, "3": 25, "4": 50, "5": 100,
    "6": 175, "7": 275, "8": 375, "9": 500, "10": 1000
};

const mockChallenge: Challenge = {
    id: 1,
    description: "No Active Challenge",
    minimum_bet: 0,
    reward: 0,
    expiry: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
    completed: true,
    completed_by: null
};

export default function Rewards() {
    const { rewards, currentRakeback, setCurrentRakeback, updateRewardsAndRakeback, xp, level } = useProfile();
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const [challenges, setChallenges] = useState<Challenge[]>([]);
    const [challenge, setChallenge] = useState<Challenge>(mockChallenge);
    const [isLoading, setIsLoading] = useState(true);
    const [isClaimingRakeback, setIsClaimingRakeback] = useState(false);
    const [claimingRewards, setClaimingRewards] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await Promise.all([
                updateRewardsAndRakeback(),
                fetchActiveChallenge()
            ]);
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const fetchActiveChallenge = async () => {
        try {
            const { data, error } = await supabase
                .from('challenges')
                .select('*')
                .gt('expiry', new Date().toISOString())
                .order('expiry', { ascending: true }) // Get the soonest expiry first
                .limit(1)
                .single(); // Fetch one challenge

            if (error) {
                throw error;
            }

            if (data) {
                setChallenge(data);
            } else {
                //toast.info("No active challenges available.");
            }
        } catch (error) {
            console.error("Error fetching active challenge:", error);
            //toast.error("Failed to load the active challenge.");
        }
    };

    const handleClaim = async (rewardKey: string) => {
        if (claimingRewards[rewardKey]) return;
        
        setClaimingRewards(prev => ({ ...prev, [rewardKey]: true }));
        
        try {
            const { data, error } = await supabase.rpc("claim_reward", { 
                v_player_id: session?.user?.id, 
                v_reward_key: rewardKey 
            });
            
            if (error) {
                console.error("Error claiming reward:", error);
            } else {
                console.log("Reward claimed successfully:", data);
                toast.success("Reward claimed successfully");
                await fetchBalances();
            }

            // After successful claim, update the rewards
            await updateRewardsAndRakeback();
        } finally {
            setTimeout(() => {
                setClaimingRewards(prev => ({ ...prev, [rewardKey]: false }));
            }, 1000);
        }
    };

    const handleClaimRakeback = async () => {
        setIsClaimingRakeback(true);
        try {
            const { data, error } = await supabase.rpc("claim_rakeback", { v_player_id: session?.user?.id });
            if (error) {
                console.error("Error claiming rakeback:", error);
            } else {
                console.log("Rakeback claimed successfully:", data);
                toast.success("Rakeback claimed successfully");
                setCurrentRakeback(0);
            }
            // After successful claim, update the rakeback
            await updateRewardsAndRakeback();
            await fetchBalances();
        } finally {
            setTimeout(() => setIsClaimingRakeback(false), 1000);
        }
    };
    const renderRewards = () => {
        return rewards.map((reward) => {
            const rewardKey = reward.reward_key.toLowerCase();
            const rewardInfo = rewardMap[rewardKey] || { description: "Unknown reward", value: "N/A" };
            const goldTokens = goldTokenMap[rewardKey] || 0;

            let title = "Unknown Reward";
            let rakebackDescription = "";
            let coinReward = 0;

            if (rewardKey === "early_adopter") {
                title = "Early Adopter Reward";
                rakebackDescription = "Permanent 2.5% increased rakeback";
                coinReward = 10;
            } else if (rewardKey === "discord_verified") {
                title = "Discord Verified Reward";
                rakebackDescription = "Permanent 2.5% increased rakeback";
                coinReward = 0;
            } else if (rewardKey.match(/^\d+$/)) {
                title = `Level ${rewardKey} Reward`;
                rakebackDescription = rewardInfo.description;
                coinReward = goldTokens;
            }

            return (
                <Paper key={rewardKey} elevation={3} sx={{ p: 2, mb: 2, width: '100%', maxWidth: 400 }}>
                    <Typography variant="h6" gutterBottom align="center" sx={{ fontSize: '1.4rem' }}>{title}</Typography>
                    <Typography variant="body1" align="center" sx={{ fontSize: '1.2rem', mb: 1 }}>
                        {rakebackDescription}
                    </Typography>
                    {coinReward > 0 && (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                            <GoldCoin style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                            <Typography variant="body1" className="text-[#05f783]" sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                {coinReward}
                            </Typography>
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={reward.reward_claimed || claimingRewards[rewardKey]}
                            onClick={() => handleClaim(rewardKey)}
                            sx={{ fontSize: '1.1rem', py: 1, px: 3 }}
                        >
                            {reward.reward_claimed ? "Claimed" : "Claim"}
                        </Button>
                    </Box>
                </Paper>
            );
        });
    };

    const renderNextLevelReward = () => {
        const nextLevel = level + 1;
        const nextReward = rewardMap[nextLevel.toString()];
        const nextGoldTokens = goldTokenMap[nextLevel.toString()] || 0;

        return (
            <Paper elevation={3} color="primary" sx={{ p: 2, mb: 2, width: '100%', maxWidth: 400 }}>
                <Typography variant="h6" gutterBottom align="center" sx={{ fontSize: '1.4rem', color: '#fff' }}>
                    Next Level Reward
                </Typography>
                <Typography variant="body1" align="center" sx={{ fontSize: '1.2rem', color: '#ccc', mb: 1 }}>
                    {nextReward?.description}
                </Typography>
                {level < 10 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                        <GoldCoin style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        <Typography variant="body1" sx={{ fontSize: '1.2rem', color: '#05f783', fontWeight: 'bold' }}>
                            {nextGoldTokens}
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 1 }}>
                        <Typography variant="body1" align="center" sx={{ fontSize: '1.4rem', color: '#ccc', mb: 1 }}>
                            You've reached the maximum level!
                        </Typography>
                    </Box>
                )}
            </Paper>
        );
    };

    return (
        isLoading ? <LoadingScreen /> :
            <Container className="min-h-full" maxWidth="sm" sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <DailyChallenge
                    challenge={challenge}
                />

                <Box sx={{ width: '100%', mb: 2 }}>
                    <LevelUpBar />
                </Box>

                {renderNextLevelReward()}

                <Box sx={{ my: 4, width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h5" gutterBottom align="center" sx={{ fontSize: '1.6rem', color: '#fff' }}>
                        Available Rewards
                    </Typography>
                    {rewards.length === 0 ? (
                        <Paper elevation={3} color="primary" sx={{ p: 2, mb: 2, width: '100%', maxWidth: 400 }}>
                            <Typography variant="h6" align="center" sx={{ fontSize: '1.2rem', color: '#ccc' }}>
                                No rewards available to claim at this time.
                            </Typography>
                        </Paper>
                    ) : (
                        renderRewards()
                    )}
                </Box>

                <Divider sx={{ my: 4, width: '100%' }} />

                <Box sx={{ width: '100%', maxWidth: 400 }}>
                    <Typography variant="h5" gutterBottom align="center" sx={{ fontSize: '1.6rem', color: '#fff' }}>
                        Rakeback
                    </Typography>
                    <Paper elevation={3} color="primary" sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                            <GoldCoin style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                            <Typography variant="body1" sx={{ fontSize: '1.4rem', fontWeight: 'bold', color: '#05f783' }}>
                                {currentRakeback.toFixed(2)}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                disabled={currentRakeback <= 0 || isClaimingRakeback}
                                onClick={handleClaimRakeback}
                                color="primary"
                                sx={{
                                    fontSize: '1.1rem',
                                    py: 1,
                                    px: 3,
                                    color: '#fff',
                                    '&:hover': { bgcolor: '#4c4c4c' },
                                    '&:disabled': { bgcolor: '#2c2c2c', color: '#666' }
                                }}
                            >
                                Claim Rakeback
                            </Button>
                        </Box>
                    </Paper>
                </Box>

            </Container>
    );
}
