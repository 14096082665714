import React, { useEffect, useState } from 'react'
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Button, IconButton, ListItemIcon, Menu, MenuItem, useMediaQuery } from "@mui/material";
import background from "../../assets/background.png";
import { GoldCoin, SilverCoin } from "../Coins";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useBalance } from "../../contexts/BalanceContext";
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import logoImage from '../../assets/newlogomaybe2.png'; // Import the logo image
import accountIcon from "../../assets/account_icon.png";
import { Typography } from '@mui/material';
import { ArrowUpward as ArrowUpwardIcon } from '@mui/icons-material';
import { FaPlus } from 'react-icons/fa';
import { SlWallet } from 'react-icons/sl';
import bankpinfail from '../../assets/bankpinfail.mp3';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { usePlayerCount } from '../../hooks/usePlayerCount';
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from 'react-router-dom'; // Update this import

// Add this styled component near the top of your file
const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        backgroundImage: `url(${background})`, // Dark background color
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
}));

export default function Header({ walletModalOpen, setWalletModalOpen, handleWalletModalOpen, setIsLoginModalOpen, setIsRegisterModalOpen }) {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [shake, setShake] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isPulsing, setIsPulsing] = useState(false);
    const theme = useTheme();
    const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md'));
    const coinSize = isMediumOrLarger ? "26px" : "22px";
    const balanceTextClass = isMediumOrLarger ? "text-[1.75rem]" : "text-[1.25rem]";
    const { goldBalance, silverBalance, balanceType, changeBalanceType, disableModifications, addSilverBalance } = useBalance();
    const { signedIn, handleLogout } = useAuth();
    const playerCount = usePlayerCount();
    const navigate = useNavigate();
    const bankPinSound = new Audio(bankpinfail);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuAnchorClose = () => {
        setMenuAnchorEl(null);
    };
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleProfileClick = () => {
        handleMenuClose();
        navigate('/profile'); // Update this line to use navigate
      };
    
      const handleExchangeClick = () => {
        // Handle exchange click
        setWalletModalOpen(true);
        handleMenuClose();
      };
    
      const handleLogoutClick = async () => {
        await handleLogout();
        handleMenuClose();
        window.location.reload();
      };


    const handleSilverAdd = () => {
        if (silverBalance >= 10000) {
            bankPinSound.play();
            setShake(true);
            setTimeout(() => {
                setShake(false);
            }, 400);
            return
        }
        addSilverBalance();
    }
    useEffect(() => {
        const interval = setInterval(() => {
          setIsPulsing(prev => !prev);
        }, 1500); // Toggle every 1.5 seconds for a more subtle effect
    
        return () => clearInterval(interval);
      }, []);

    return (
        <div style={{ backgroundImage: `url(${background})` }} className="flex items-center shadow-lg min-w-[100%] h-16">
            <h1 className="flex-1 font-bold select-none flex items-center">
                <Link
                    to="/"
                    className="cursor-pointer"
                >
                    <img
                        src={logoImage}
                        className="h-auto w-auto object-contain md:ml-4"
                        alt="Sand Casino Logo"
                        style={{
                            padding: '2px 0',
                            maxHeight: isMediumOrLarger ? '64px' : '50px',
                            maxWidth: isMediumOrLarger ? '260px' : '180px',
                        }}
                    />
                </Link>
            </h1>

            {signedIn && <div className="flex items-center bg-primary-900 rounded-lg pl-3 h-10 relative">
                {(goldBalance >= 0 || silverBalance >= 0) && (
                    <div className="flex items-center h-full pt-1 cursor-pointer" onClick={(event) => { if (menuAnchorEl) handleMenuAnchorClose(); else setMenuAnchorEl(event.currentTarget) }}>
                        <h3 className={`text-[#05f783] leading-none mr-1 ${balanceTextClass} whitespace-nowrap overflow-hidden text-ellipsis max-w-[80px] md:max-w-none`}>
                            {balanceType === 'gold' ? goldBalance : silverBalance}
                        </h3>
                        {balanceType === 'gold' ? (
                            <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                        ) : (
                            <SilverCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                        )}
                        <button
                            aria-controls="balance-menu"
                            aria-haspopup="true"
                            onClick={handleMenuClick}
                            className={`text-white mt-2 ${disableModifications ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            disabled={disableModifications}
                        >
                            <ArrowDropDownIcon className="align-top" style={{ width: coinSize, height: coinSize, color: '#05f783' }} />
                        </button>
                        <StyledMenu
                            id="balance-menu"
                            anchorEl={menuAnchorEl}
                            keepMounted
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuAnchorClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <MenuItem onClick={() => { changeBalanceType('gold'); handleMenuAnchorClose() }}>
                                <div className="flex items-center w-full justify-end">
                                    <h3 className="flex-2 text-[#05f783] leading-none mr-1">
                                        {goldBalance}
                                    </h3>
                                    <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                                </div>
                            </MenuItem>
                            <MenuItem onClick={() => { changeBalanceType('silver'); handleMenuAnchorClose(); }}>
                                <div className="flex items-center w-full justify-end">
                                    <h3 className="text-[#05f783] leading-none mr-1">
                                        {silverBalance}
                                    </h3>
                                    <SilverCoin additionalStyles="ml-1 mb-1" style={{ width: coinSize, height: coinSize }} />
                                </div>
                            </MenuItem>
                        </StyledMenu>
                    </div>
                )}
                {signedIn && (
                    balanceType === 'gold' ? (
                        <button
                            data-testid='play-button'
                            onClick={handleWalletModalOpen}
                            className="rs-button ml-1 h-full flex items-center justify-center"
                        >
                            <SlWallet />
                        </button>
                    ) : (
                        <div className="relative">
                            <button
                                data-testid='play-button'
                                onClick={handleSilverAdd}
                                disabled={disableModifications}
                                className={`rs-button ml-1 h-full flex items-center justify-center ${shake ? "shake-animation" : ""} ${disableModifications ? "cursor-not-allowed" : ""}`}
                                style={{ position: 'relative', width: '40px', height: '40px' }}
                            >
                                <FaPlus />
                            </button>
                        </div>
                    )
                )}
            </div>}
            <div className="flex-1 flex justify-end items-center mr-14">
                {/* Player count display */}
                {playerCount > 0 && (
                    <div className="hidden md:block flex justify-end ml-10 mr-8 select-none">
                        <div className="flex items-center bg-primary-900 rounded-full px-2 py-1">
                            <div
                                style={{
                                    width: '8px',
                                    height: '8px',
                                    borderRadius: '50%',
                                    marginRight: '8px',
                                    backgroundColor: '#4ade80',
                                    boxShadow: isPulsing
                                        ? '0 0 0 4px rgba(74, 222, 128, 0.1), 0 0 0 2px rgba(74, 222, 128, 0.3)'
                                        : '0 0 0 0 rgba(74, 222, 128, 0)',
                                    transition: 'all 0.8s ease',
                                }}
                            ></div>
                            <span className="text-white text-md font-medium">{playerCount.toLocaleString()} playing</span>
                        </div>
                    </div>
                )}
                {signedIn ? (
                    <>
                        <IconButton
                            onClick={handleMenuOpen}
                            color="inherit"
                            sx={{
                                backgroundColor: 'hsla(24, 12.82%, 35%, 100%)', // Tailwind primary-400 color
                                '&:hover': {
                                    backgroundColor: 'hsla(204, 19.23%, 45%, 100%)', // Tailwind accent-500 color
                                },
                            }}
                        >
                            <img src={accountIcon} alt="Account" style={{ width: 24, height: 24 }} />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                            MenuListProps={{ "aria-labelledby": "basic-button" }}
                        >
                            <MenuItem onClick={handleProfileClick}>
                                <ListItemIcon>
                                    <AccountCircleIcon />
                                </ListItemIcon>
                                Profile
                            </MenuItem>
                            <MenuItem onClick={handleExchangeClick}>
                                <ListItemIcon>
                                    <SwapHorizIcon />
                                </ListItemIcon>
                                Exchange
                            </MenuItem>
                            <MenuItem onClick={handleLogoutClick}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                Log Out
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <div className="flex items-center">
                        <Button
                            variant="outlined"
                            onClick={() => setIsLoginModalOpen(true)}
                            color="inherit"
                            sx={{
                                marginRight: 2,
                                "&:hover": {
                                    backgroundColor: "hsla(204, 19.23%, 55%, 100%)",
                                },
                            }}
                        >
                            <span className="font-bold">Login</span>
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setIsRegisterModalOpen(true)}
                            sx={{
                                backgroundColor: "hsla(204, 19.23%, 45%, 100%)",
                                "&:hover": {
                                    backgroundColor: "hsla(204, 19.23%, 55%, 100%)",
                                },
                            }}
                        >
                            <span className="font-bold">Register</span>
                        </Button>
                    </div>
                )}
            </div>
        </div>

    )
}
