import { useState } from 'react';
import { useBalance } from '../contexts/BalanceContext';

export const useBet = (initialBet = 0) => {
  const [bet, setBet] = useState(initialBet);
  const { MAXBET, getActiveBalance } = useBalance();
  const roundToTwoDecimals = (value: number) => {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  };

  const handleBetChange = (e: { target: { value: any; }; }) => {
    let value = Number(e.target.value);
    if (value > MAXBET) {
      value = MAXBET;
    }
    setBet(value); // Set the exact value during input
  };

  const handleBetBlur = () => {
    setBet(roundToTwoDecimals(bet)); // Round when input loses focus
  };

  const handleHalfBet = () => {
    const newBet = bet / 2 < 1 ? 1 : bet / 2;
    setBet(roundToTwoDecimals(newBet));
  };

  const handleDoubleBet = () => {
    let newBet = bet * 2 > MAXBET ? MAXBET : bet * 2;
    newBet = newBet > getActiveBalance() ? getActiveBalance() : newBet;
    setBet(roundToTwoDecimals(newBet));
  };

  return {
    bet,
    setBet,
    handleBetChange,
    handleBetBlur,
    handleHalfBet,
    handleDoubleBet,
  };
};