import React, { useState } from 'react';
import { FaDice, FaChartBar, FaRandom, FaLock, FaCode, FaCopy } from 'react-icons/fa';
import { useFairness } from '../../hooks/useFairness';
import { Paper, Typography, Grid, Button, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Tooltip, Box } from '@mui/material';
import './Fairness.css';
import LoadingScreen from '../LoadingScreen';
const fairnessItems = [
  { icon: <FaDice />, text: 'Provably Fair' },
  { icon: <FaChartBar />, text: 'RNG Certified' },
  { icon: <FaRandom />, text: 'Random Number Generation' },
  { icon: <FaLock />, text: 'Secure Encryption' },
  { icon: <FaCode />, text: 'Open Source Algorithms' },
];

const games = [
  'All Games', 'Blackjack',  'Ultimate Texas Hold\'em', 'Duel Arena',
  'Dharok\'s Deathmatch', 'Roulette', 'Dice', 'Plinko', 'Flower Poker', 'Mines', 'Palm Tower'
];

export default function Fairness() {
  const [selectedGame, setSelectedGame] = useState('All Games');
  const { fairnessData, isLoading } = useFairness();
  const [copiedGameId, setCopiedGameId] = useState(null);

  const getVerificationUrl = (gameId, gameType) => {
    // Replace this with your actual URL generation logic
    if (gameType === 'Blackjack' || gameType === 'Free Bet Blackjack') {
      return `https://onecompiler.com/javascript/42njcjax8`;
    } else if (gameType === 'Ultimate Texas Hold\'em') {
      return `https://onecompiler.com/javascript/42p2cqaep`;
    } else if (gameType === 'Duel Arena') {
      return `https://onecompiler.com/javascript/42p2ecc67`;
    } else if (gameType === 'Dharok\'s Deathmatch') {
      return `https://onecompiler.com/javascript/42p2exqbk`;
    } else if (gameType === 'Flower Poker') {
      return `https://onecompiler.com/javascript/42p2g92y2`;
    } else if (gameType === 'Roulette') {
      return `https://onecompiler.com/javascript/42p2gqsc8`;
    } else if (gameType === 'Dice') {
      return `https://onecompiler.com/javascript/42p2jjrtb`;
    } else if (gameType === 'Plinko') {
      return `https://onecompiler.com/javascript/42p2mkcgv  `;
    } else if (gameType === 'Palm Tower') {
      return `https://onecompiler.com/javascript/42zkrq5x8`;
    } else {
      return `https://onecompiler.com/javascript/42p5dttcd`;
    }
  };

  const copyToClipboard = (gameId, serverSeed, clientSeed, nonce) => {
    const content = `const serverSeed = '${serverSeed}';\nconst clientSeed = '${clientSeed}';\nconst nonce = '${nonce}';`;
    navigator.clipboard.writeText(content).then(() => {
      setCopiedGameId(gameId);
      setTimeout(() => setCopiedGameId(null), 2000);
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const filteredFairnessData = selectedGame === 'All Games'
    ? fairnessData
    : fairnessData.filter(game => game.game_type === selectedGame);

  const getVerificationUrlForSelected = () => {
    if (selectedGame === 'All Games') {
      return getVerificationUrl('', 'Blackjack'); // Default to blackjack
    }
    return getVerificationUrl('', selectedGame);
  };

  return (
    isLoading ?<LoadingScreen /> :
    <Paper className="fairness-container min-h-screen" elevation={3}>
      <Typography variant="h4" className="fairness-title">Fairness</Typography>
      <Grid container spacing={2} className="fairness-list">
        {fairnessItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper className="fairness-item">
              <Box className="fairness-icon-wrapper">{item.icon}</Box>
              <Typography variant="body1" className="fairness-text">{item.text}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box className="fairness-section">
        <Typography variant="h5" className="fairness-subtitle">Test Our Games</Typography>
        <Select
          className="fairness-game-select"
          value={selectedGame}
          onChange={(e) => setSelectedGame(e.target.value)}
        >
          {games.map((game, index) => (
            <MenuItem key={index} value={game}>{game}</MenuItem>
          ))}
        </Select>
        <div className='flex justify-center items-center'>
          <Button
            href={getVerificationUrlForSelected()}
            target="_blank"
            rel="noopener noreferrer"
            variant="contained"
            className="fairness-verify-btn"
          >
            View Code
          </Button>
        </div>
      </Box>
      <Box className="fairness-section">
        <Typography variant="h5" className="fairness-subtitle">Your Games</Typography>
        <TableContainer component={Paper} className="fairness-game-info">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Game ID</TableCell>
                <TableCell>Game Type</TableCell>
                <TableCell>Server Seed</TableCell>
                <TableCell>Client Seed</TableCell>
                <TableCell>Nonce</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFairnessData.length > 0 ? (
                filteredFairnessData.map((game) => (
                  <TableRow key={game.game_id}>
                    <TableCell>{game.game_id}</TableCell>
                    <TableCell>{game.game_type}</TableCell>
                    <TableCell>{game.server_seed}</TableCell>
                    <TableCell>{game.client_seed}</TableCell>
                    <TableCell>{game.nonce}</TableCell>
                    <TableCell>
                      <Box className="fairness-copy-wrapper">
                        <Tooltip title="Copy Code" arrow>
                          <IconButton
                            onClick={() => copyToClipboard(game.game_id, game.server_seed, game.client_seed, game.nonce)}
                            className="fairness-copy-btn"
                          >
                            <FaCopy />
                          </IconButton>
                        </Tooltip>
                        {copiedGameId === game.game_id && (
                          <Typography variant="caption" className="fairness-copy-success">
                            Copied!
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button
                        href={getVerificationUrl(game.game_id, game.game_type)}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="contained"
                        className="fairness-verify-btn"
                      >
                        Verify
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body1">
                      You have no games played yet.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
}