import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/AuthContext.js";
import { useBalance } from "../../contexts/BalanceContext.js";
import { fetchAndUpdateBalance, supabase } from "../../api/supabaseClient.js";
import GeneralBettingWindow from "../GeneralBettingWindow";
import Phaser from "phaser";
import "./DuelArena.css";
import { toast } from 'react-toastify';
import bankpinfail from '../../assets/bankpinfail.mp3';
import RulesComponent from '../../components/RulesComponent';
import { duelArenaRules } from '../../components/gameRules';
import PayoutDisplay from '../Ultimate-Texas/PayoutDisplay';
import { useVolume } from '../../contexts/VolumeContext'; // Import useVolume hook
import { useBet } from '../../hooks/useBet';
import EnhancedWinningsComponent from '../EnhancedWinningsComponent';
import withFadeInDelay from '../withFadeInDelay';
import confetti from 'canvas-confetti';
const FadedInWinningsComponent = withFadeInDelay(EnhancedWinningsComponent, 50);

let player1, player2;
let player1HealthBar, player2HealthBar;
let player1HitSplat, player2HitSplat;
let winnerText;
let gameTimer;
let attackSound; // Declare a global variable outside of the component function

export default function DuelArena() {
  const { session, signedIn } = useAuth();
  const {
    bet: playerBet,
    setBet: setPlayerBet,
    handleBetChange: handlePlayerBetChange,
    handleBetBlur: handlePlayerBetBlur,
    handleHalfBet: handlePlayerHalfBet,
    handleDoubleBet: handlePlayerDoubleBet,
  } = useBet(!signedIn ? 1 : 0);

  const {
    bet: big25Bet,
    setBet: setBig25Bet,
    handleBetChange: handleBig25BetChange,
    handleBetBlur: handleBig25BetBlur,
    handleHalfBet: handleBig25HalfBet,
    handleDoubleBet: handleBig25DoubleBet,
  } = useBet(0);

  const [game, setGame] = useState(null); // To hold the Phaser game instance
  const [gameStarted, setGameStarted] = useState(false);
  const [winnings, setWinnings] = useState(0);
  const [big25winnings, setbig25Winnings] = useState(0);
  const [player1Hits, setPlayer1Hits] = useState([]);
  const [player2Hits, setPlayer2Hits] = useState([]);
  const [duelId, setDuelId] = useState(null);
  const [gameFetched, setGameFetched] = useState(false);
  const [result, setResult] = useState("");
  const [speed, setSpeed] = useState(1);
  const gameOver = useRef(false);
  const { MAXBET, balanceType, changeBalanceType, fetchBalances, getActiveBalance, setDisableModifications } = useBalance();

  const [shake, setShake] = useState(false);
  const bankPinSound = new Audio(bankpinfail);

  const show = useRef(false);

  const big25 = [
    "Three 25s: 20 to 1",
    "Two 25s: 6 to 1",
    "One 25: 1 to 1",
    "Player Win: Push"
  ]

  const { volumes, mutedStates, setActiveGame } = useVolume(); // Use the useVolume hook to get volumes


  // Phaser game configuration
  const config = {
    type: Phaser.AUTO,
    width: 800,
    height: 600,
    parent: "gameContainer",
    // scale: {
    //   mode: Phaser.Scale.FIT, // Scale the game to fit the parent
    //   autoCenter: Phaser.Scale.CENTER_BOTH, // Center the game
    // },
    scene: {
      preload: preload,
      create: create,
    },
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
      },
    },
    transparent: true, // Duel Arena color
    autoFocus: false,
  };
  // Game variables

  function preload() {
    this.load.image(
      "player",
      "/dasprites/new1.png",
    );
    this.load.image(
      "whipAnimation1",
      "/dasprites/new2.png",
    ); // Loading the whip animation frame
    this.load.image(
      "whipAnimation2",
      "/dasprites/new3.png",
    ); // Loading the whip animation frame
    this.load.image(
      "whipAnimation3",
      "/dasprites/new4.png",
    ); // Loading the whip animation frame
    this.load.image(
      "hit",
      "/dasprites/hit.png",
    );
    this.load.image(
      "hitSplat",
      "/dasprites/red_hitsplat.webp",
    );
    this.load.image(
      "hitSplat2",
      "/dasprites/red_hitsplat_tinted.webp",
    );
    this.load.image(
      "blueSplat",
      "/dasprites/blue_hitsplat.webp",
    );
    this.load.image(
      "blueSplat2",
      "/dasprites/blue_hitsplat_tinted.webp",
    );
    this.load.image(
      "background",
      "/dasprites/duelarenabackground.png",
    );
    this.load.audio("attackSound", "/dasprites/attack.wav");
    this.load.image("arrow", "/dasprites/arrow_down.png");
  }

  function create() {
    // Set up player variables
    const playerScale = 0.5;
    const shadowAlpha = 0.5;  // Slightly reduced alpha
    const shadowOffsetY = 55;  // Increased Y offset
    const shadowScaleX = 1.2;  // Wider shadow
    const shadowScaleY = 0.15; // Flatter shadow

    // Create shadow sprites instead of ellipses
    let player1Shadow = this.add.sprite(400, 300, "player")
      .setScale(playerScale * shadowScaleX, playerScale * shadowScaleY)  // Wider and flatter
      .setTint(0x000000)
      .setAlpha(shadowAlpha)
      .setDepth(0);
    
    let player2Shadow = this.add.sprite(450, 300, "player")
      .setScale(playerScale * shadowScaleX, playerScale * shadowScaleY)  // Wider and flatter
      .setFlipX(true)
      .setTint(0x000000)
      .setAlpha(shadowAlpha)
      .setDepth(0);

    // Add players
    player1 = this.physics.add.sprite(400, 300, "player")
      .setScale(playerScale)
      .setDepth(1);
    
    player2 = this.physics.add.sprite(450, 300, "player")
      .setScale(playerScale)
      .setFlipX(true)
      .setDepth(1);

    // Update shadows function
    const updateShadow = (playerSprite, shadowSprite) => {
      shadowSprite.setTexture(playerSprite.texture.key);
      shadowSprite.setScale(
        playerSprite.scaleX * shadowScaleX, 
        playerSprite.scaleY * shadowScaleY
      );
      shadowSprite.setPosition(
        playerSprite.x, 
        playerSprite.y + shadowOffsetY
      );
      shadowSprite.setFlipX(playerSprite.flipX);
    };

    // Update shadow positions and animations
    this.events.on('update', () => {
      updateShadow(player1, player1Shadow);
      updateShadow(player2, player2Shadow);
    });

    // Store shadow references globally for debugging
    window.debug = {
      player1Shadow,
      player2Shadow,
      player1,
      player2
    };

    // Add a softer glow effect
    const glowFX = player1.postFX.addGlow(0xffffff, 0, 0, false, 0.05, 24);
    const glowFX2 = player2.postFX.addGlow(0xffffff, 0, 0, false, 0.05, 24);

    // Create an arrow with animation
    let arrow = this.add.sprite(player2.x + 105, player2.y - 160, "arrow")
      .setScale(1.5)
      .setDepth(2);
    
    // Add "House" text above the arrow with matching style
    let houseText = this.add.text(arrow.x, arrow.y - 30, "House", {
      fontSize: "20px",
      fontFamily: "RuneScape UF",
      fill: "#FCFA47",
      stroke: '#000000',
      strokeThickness: 4,
      align: 'center'
    })
    .setOrigin(0.5)  // Center the text above the arrow
    .setDepth(2);
    
    // Add arrow bounce animation and include the text
    this.tweens.add({
      targets: [arrow, houseText],  // Animate both arrow and text
      y: '-=5',  // Move 5 pixels down (reduced bounce)
      duration: 1000,
      ease: 'Sine.easeInOut',
      yoyo: true,
      repeat: -1
    });

    // Add highlighted tile with improved visuals
    let highlightedTile = this.add.graphics();
    highlightedTile.fillStyle(0x00FFFF, 0.05);
    highlightedTile.fillRect(player1.x - 160, player1.y, 100, 100);
    highlightedTile.lineStyle(2, 0x00FFFF, 0.3);
    highlightedTile.strokeRect(player1.x - 160, player1.y, 100, 100);
    highlightedTile.setDepth(0);

    // Initialize health and health bars
    player1.health = 99;
    player2.health = 99;

    // Improve hit splat visuals
    const hitSplatScale = 2.5;
    const hitSplatConfig = {
      scale: hitSplatScale,
      visible: false,
      depth: 2
    };

    player1HitSplat = this.add.image(player1.x, player1.y, "hitSplat").setScale(hitSplatScale).setVisible(false).setDepth(2);
    player2HitSplat = this.add.image(player2.x, player2.y, "hitSplat").setScale(hitSplatScale).setVisible(false).setDepth(2);

    // Improve hit splat text styling
    const hitTextStyle = {
      fontSize: "20px",
      fill: "#FFF",
      fontFamily: "RuneScape UF",
      stroke: '#000',
      strokeThickness: 4,
      shadow: { blur: 2, color: '#000', fill: true }
    };

    player1HitSplat.text = this.add.text(player1.x, player1.y - 50, "", hitTextStyle)
      .setVisible(false)
      .setOrigin(0.5, 0.5)
      .setDepth(3);
      
    player2HitSplat.text = this.add.text(player2.x, player2.y - 50, "", hitTextStyle)
      .setVisible(false)
      .setOrigin(0.5, 0.5)
      .setDepth(3);

    // Create improved health bars
    player1HealthBar = createHealthBar(this, player1.x - 120, player1.y - player1.displayHeight / 2 - 20);
    player2HealthBar = createHealthBar(this, player2.x + 80, player2.y - player2.displayHeight / 2 - 20);

    // Initialize attack sound
    attackSound = this.sound.add('attackSound');
    
    // Start combat
    startCombat(this);

    this.game.events.on('hidden', () => {
      this.scene.pause();
      this.game.sound.pauseAll();
    });

    this.game.events.on('visible', () => {
      this.scene.resume();
      this.game.sound.resumeAll();
    });
  }

  function attack(scene, attacker, target, targetHitSplat, targetHealthBar, damage) {
    if (!gameOver.current) {
      target.health -= damage;
      target.health = Math.max(target.health, 0); // Prevent negative health
      attacker.setTexture("player");
  
      // Play attack sound using the global sound object
      attackSound.volume = mutedStates["duel"] ? 0 : volumes["duel"] || 1; // Set the volume for duel
      attackSound.play();
  
      if (damage === 0) {
        targetHitSplat.setTexture(target === player1 ? "blueSplat2" : "blueSplat");
      } else {
        targetHitSplat.setTexture(target === player1 ? "hitSplat2" : "hitSplat");
      }
  
      // Schedule the subsequent frames using Phaser's timer
      scene.time.delayedCall(100/speed, () => {
        attacker.setTexture("whipAnimation1").setScale(0.55);
        // Shadow will automatically update in the next frame
      });
  
      scene.time.delayedCall(200/speed, () => {
        attacker.setTexture("whipAnimation2").setScale(0.55);
        (attacker === player1 ? player2 : player1).setTexture("hit").setScale(0.7);
        // Shadow will automatically update in the next frame
      });
  
      scene.time.delayedCall(350/speed, () => {
        attacker.setTexture("whipAnimation3");
        // Shadow will automatically update in the next frame
      });
  
      scene.time.delayedCall(500/speed, () => {
        attacker.setTexture("player").setScale(0.5);
        // Shadow will automatically update in the next frame
      });
  
      scene.time.delayedCall(600/speed, () => {
        (attacker === player1 ? player2 : player1).setTexture("player").setScale(0.5);
        targetHitSplat.setVisible(false);
        targetHitSplat.text.setVisible(false);
        // Shadow will automatically update in the next frame
      });
  
      // Positioning and showing the hit splat centered on the target
      targetHitSplat.x = attacker === player2 ? target.x - 110 : target.x + 110;
      targetHitSplat.y = target.y - 20; // Adjusted for visual effect
      targetHitSplat.setVisible(true);
  
      // Update and position the hit splat text
      targetHitSplat.text.setText(damage.toString()); // Set the hit number
      targetHitSplat.text.x = targetHitSplat.x;
      targetHitSplat.text.y = targetHitSplat.y;
      targetHitSplat.text.setVisible(true);
  
      // Update health display and health bar
      if (damage > 0) updateHealthBar(targetHealthBar, target.health);
  
      if (target.health <= 0 && !gameOver.current) {
        gameOver.current = true;
        show.current = true;
        setGameStarted(false);
        let winner = target === player1 ? "House Wins" : "Player Wins";
        setResult(winner);
        // Remove the winnerText update
        // winnerText.setText(winner).setVisible(true);
        scene.combatTimer.remove(); // Stop the combat when the game is over
      }
    }
  }

  function startCombat(scene) {
    let turn = 1; // 1 for player1's turn, 2 for player2's turn
    let p1Idx = 0;
    let p2Idx = 0;
    let damage;
  
    scene.combatTimer = scene.time.addEvent({
      delay: 1000/speed,
      callback: () => {
        if (!gameOver.current) {
          if (turn === 2) {
            damage = player2Hits[p2Idx];
            p2Idx++;
            attack(scene, player1, player2, player2HitSplat, player2HealthBar, damage);
            turn = 1; // Next turn will be player 1's
          } else {
            damage = player1Hits[p1Idx];
            p1Idx++;
            attack(scene, player2, player1, player1HitSplat, player1HealthBar, damage);
            turn = 2; // Next turn will be player 2's
          }
        } else {
          scene.combatTimer.remove();
        }
      },
      loop: true
    });
  }

  function createHealthBar(scene, x, y) {
    const width = 50;
    const height = 8;
    const padding = 2;

    // Create container for health bar
    const container = scene.add.container(x, y);

    // Add border
    const border = scene.add.graphics()
      .lineStyle(1, 0x000000, 1)
      .strokeRect(-padding, -padding, width + (padding * 2), height + (padding * 2));

    // Background (red)
    const background = scene.add.graphics()
      .fillStyle(0x880000, 1)
      .fillRect(0, 0, width, height);

    // Foreground (green)
    const bar = scene.add.graphics()
      .fillStyle(0x00aa00, 1)
      .fillRect(0, 0, width, height);

    container.add([border, background, bar]);
    container.setDepth(2);

    return { bar, background, container };
  }

  function updateHealthBar(healthBar, health) {
    const width = 50;
    healthBar.bar.clear();
    healthBar.bar.fillStyle(0x00aa00, 1);
    healthBar.bar.fillRect(0, 0, width * (health / 100), 8);
  }
  async function createAndFetchGame() {
    setResult("");
    let { data, error } = {};
    if (signedIn) {
      ({ data, error } = await supabase.rpc("start_duel", {
        v_bet: playerBet,
        v_big25bet: big25Bet,
        v_player_id: session?.user.id,
        v_type: balanceType
      }));
    } else {
      ({ data, error } = await supabase.rpc("start_duel_guest", {
        v_bet: playerBet,
        v_big25bet: big25Bet
      }));
    }
    await fetchBalances();

    if (error) {
      setGameStarted(false);
      toast.error(error.message);
      bankPinSound.play();
      setShake(true);
      setTimeout(() => {
        setShake(false);
      }, 200);
      console.error("Error creating game:", error);
      return null;
    }

    return data;
  }

  useEffect(() => {
    const startGame = async () => {
      if (playerBet + big25Bet > getActiveBalance() && signedIn) {
        toast.error("Insufficient balance. Bet cannot be higher than balance.");
        bankPinSound.play();
        setGameStarted(false);
        setShake(true);
        setTimeout(() => {
          setShake(false);
        }, 200);
        return;
      }
      const newGameState = await createAndFetchGame();
      if (newGameState) {
        confetti.reset();
        show.current = false;
        if(signedIn) {
          setDuelId(newGameState.id);
          setWinnings(newGameState.winnings);
          setbig25Winnings(newGameState.big25winnings);
          setPlayer1Hits(newGameState.player1_hits);
          setPlayer2Hits(newGameState.player2_hits);
        } else {
          setWinnings(newGameState.f7);
          setbig25Winnings(newGameState.f8);
          setPlayer1Hits(newGameState.f5);
          setPlayer2Hits(newGameState.f6);
        }
      }
    };
    if (gameStarted && !gameFetched) {
      startGame();
    }
  }, [gameStarted]);
  useEffect(() => {
    const fetchActiveGame = async () => {
      const { data, error } = await supabase.rpc("duel_fetch_active", {
        v_player_id: session?.user.id,
      });
      if (error) {
        console.error("Error fetching active game:", error);
        return null;
      }
      if (data) {
        setGameFetched(true)
        setDuelId(data.id);
        changeBalanceType(data.type);
        setPlayerBet(data.bet);
        setBig25Bet(data.big25bet);
        setWinnings(data.winnings);
        setbig25Winnings(data.big25winnings);
        setPlayer1Hits(data.player1_hits);
        setPlayer2Hits(data.player2_hits);
        setGameStarted(true);
      }
    }
    if (session?.user.id.length > 0) {
      fetchActiveGame();
    }
  }, [session?.user.id]);
  useEffect(() => {
    if (player1Hits.length > 0 && player2Hits.length > 0) {
      gameOver.current = false;
      if (game) {
        game.destroy(true);
      }
      const newGame = new Phaser.Game(config);
      setGame(newGame);
    }
  }, [player1Hits, player2Hits]);
  useEffect(() => {
    return () => {
      if (game) {
        game.destroy(true);
      }
    };
  }, [game]);

  useEffect(() => {
    const endGame = async () => {
      const { data, error } = await supabase.rpc("end_duel", {
        duel_id: duelId,
        v_player_id: session?.user.id,
      });
      if (error) {
        console.error("Error ending game:", error);
      }
      setGameFetched(false);
      await fetchBalances();
    };
    if (gameOver.current && signedIn) {
      endGame();
    }
  }, [gameOver.current]);
  useEffect(() => {
    setDisableModifications(gameStarted);
  }, [gameStarted])
  useEffect(() => {
    setActiveGame('duel');
    return () => {
      setDisableModifications(false);
    }
  }, [])


  const backgroundImage = "/dasprites/duelarenabackground.png";
  return (
    <>
      <div
        className="main-container">
        <GeneralBettingWindow
          bets={[
            {
              name: "Amount",
              bet: playerBet,
              handleAmountChange: handlePlayerBetChange,
              handleBlur: handlePlayerBetBlur,
              handleHalfBet: handlePlayerHalfBet,
              handleDoubleBet: handlePlayerDoubleBet,
              valid: playerBet > 0,
              winnings: winnings,
              winState: result
            },
            {
              name: "Big 25",
              bet: big25Bet,
              handleAmountChange: handleBig25BetChange,
              handleBlur: handleBig25BetBlur,
              handleHalfBet: handleBig25HalfBet,
              handleDoubleBet: handleBig25DoubleBet,
              valid: big25Bet >= 0,
              winnings: big25winnings,
              anteInfo: !(winnings > 0),
              sideBet: true,
              winState: result
            },
          ]}
          startGame={() => {
            setGameStarted(true);
          }}
          gameStarted={(!gameOver.current && gameStarted) || gameFetched}
          signedIn={signedIn}
          guest={!signedIn}
          gameOver={show.current}
          showChips={true}
          shake={shake}
          balanceType={balanceType}
          gameId="duel"
          speed={speed}
          setSpeed={setSpeed}
        />
        <div
          className="flex flex-grow items-center justify-center game-container relative"
          style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
        // style={{
        //   backgroundImage: `url(${backgroundImage})`,
        //   backgroundSize: "cover", // Cover the entire area of the div
        //   backgroundPosition: "center", // Center the background image
        //   backgroundRepeat: "no-repeat", // Do not repeat the image
        // }}
        >
          <div id="gameContainer" className="relative">
            {gameOver.current && (
              <FadedInWinningsComponent
                winnings={winnings + big25winnings}
                push={false}
                gameOver={show.current}
                shouldPlaySound={false}
                additionalStyles={'w-24 h-24 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10'}
              />
            )}
          </div>
            <div className="hidden lg:block absolute top-2/3 left-1/2 transform -translate-x-1/2 flex flex-col items-center justify-center z-10">
              <PayoutDisplay name="Big 25" payouts={big25} additionalStyles='bg-primary-600' disableWidth={1}/>
            </div>
        </div>
      </div>
      <RulesComponent rules={duelArenaRules} />
    </>
  );
}
