import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, IconButton, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions } from '@mui/material';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { GoldCoin } from '../Coins';
import { supabase } from '../../api/supabaseClient';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";
import { useBalance } from '../../contexts/BalanceContext';

interface CryptoTransactionModalProps {
    open: boolean;
    onClose: () => void;
    type: 'deposit' | 'withdraw';
}

const CryptoTransactionModal: React.FC<CryptoTransactionModalProps> = ({ open, onClose, type }) => {
    const [amount, setAmount] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [amountError, setAmountError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [isUSD, setIsUSD] = useState(true);
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const tokenPrice = 0.20;
    const { session } = useAuth();
    const { goldBalance } = useBalance();
    const [referralCode, setReferralCode] = useState('');
    const [isReferralApplied, setIsReferralApplied] = useState(false);
    const [referralError, setReferralError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const validateAmount = (value: string) => {
        const numAmount = parseFloat(value);
        if (isNaN(numAmount) || numAmount <= 0) {
            setAmountError('Please enter a valid amount greater than 0');
            return false;
        }
        
        if (type === 'withdraw') {
            const tokensToWithdraw = isUSD ? numAmount / tokenPrice : numAmount;
            if (tokensToWithdraw > goldBalance) {
                setAmountError(`Insufficient balance. Maximum available: ${isUSD ? (goldBalance * tokenPrice).toFixed(2) + ' USD' : goldBalance + ' tokens'}`);
                return false;
            }
        }
        
        if (isUSD && numAmount < 10) {
            setAmountError('Minimum amount is $10 USD');
            return false;
        }
        
        setAmountError('');
        return true;
    };

    const validateWalletAddress = (address: string) => {
        const ltcRegex = /^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/;
        if (!address) {
            setAddressError('Wallet address is required');
            return false;
        }
        if (!ltcRegex.test(address)) {
            setAddressError('Invalid Litecoin wallet address');
            return false;
        }
        setAddressError('');
        return true;
    };

    const getMaxAmount = () => {
        if (type === 'withdraw') {
            return isUSD 
                ? (goldBalance * tokenPrice).toFixed(2)  // Max USD
                : goldBalance.toString();                // Max tokens
        }
        return '';  // No max for deposits
    };

    const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        
        // Allow empty value or valid numbers
        if (value === '' || (!isNaN(Number(value)) && Number(value) >= 0)) {
            if (type === 'withdraw') {
                const numValue = Number(value);
                const maxAmount = isUSD 
                    ? goldBalance * tokenPrice
                    : goldBalance;
                
                // If input exceeds max, set to max
                if (numValue > maxAmount) {
                    setAmount(maxAmount.toString());
                    return;
                }
            }
            setAmount(value);
            if (value !== '') validateAmount(value);
        }
    };

    const handleSwap = () => {
        setIsUSD(!isUSD);
        setAmount(''); // Reset amount when swapping
    };

    const calculateConversion = () => {
        if (amount === '') return '0';
        const numAmount = parseFloat(amount);
        return isUSD
            ? (numAmount / tokenPrice).toFixed(2)
            : (numAmount * tokenPrice).toFixed(2);
    };

    const openChatWithMessage = () => {
        if (window.Tawk_API) {
            window.Tawk_API.maximize();
            window.Tawk_API.setAttributes({
                name: session?.user?.user_metadata?.username
            }, function(error: any) {
                if (error) {
                    console.error('Error setting Tawk attributes:', error);
                } else {
                    // Send message after attributes are set successfully
                    window.Tawk_API.addEvent('crypto-transaction', {
                        type: 'withdrawal',
                        amount: isUSD ? `$${amount} USD` : `${amount} Gold Tokens`,
                    }, function(error: any) {
                        if (error) {
                            console.error('Error sending Tawk event:', error);
                        }
                    });
                }
            });
        } else {
            console.error('Tawk API not available');
        }
    };

    const handleSubmit = async () => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        
        const usdAmount = isUSD ? amount : calculateConversion();
        if (!validateAmount(usdAmount)) {
            setIsSubmitting(false);
            return;
        }

        if (type === 'deposit') {
            try {
                const { data, error } = await supabase.functions.invoke('create-invoice', {
                    body: { 
                        amount: parseFloat(usdAmount), 
                        userId: session.user.id,
                        type: 'deposit',
                        referralCode: referralCode || null
                    },
                });
                if (data && data.paymentUrl) {
                    window.open(data.paymentUrl, '_blank');
                    onClose();
                    window.location.href = '/exchanges?view=crypto';
                } else {
                    throw error;
                }
            } catch (error) {
                console.error('Error creating invoice:', error);
                toast.error('An error occurred while creating the invoice. Enter valid amount between 10 and 5000 USD');
            } finally {
                setTimeout(() => setIsSubmitting(false), 1000);
            }
        } else {
            try {
                const coinsAmount = isUSD ? parseFloat(calculateConversion()) : parseFloat(amount);

                const { data, error } = await supabase.functions.invoke('create-withdrawal', {
                    body: { 
                        userId: session.user.id,
                        amount: parseFloat(usdAmount),
                        walletAddress: walletAddress
                    },
                });

                if (error) throw error;

                if (data?.success) {
                    toast.success('Withdrawal request created successfully');
                    setInfoDialogOpen(true);
                    onClose();
                } else {
                    throw new Error('Failed to create withdrawal request');
                }
            } catch (error) {
                console.error('Error creating withdrawal:', error);
                toast.error('An error occurred while creating the withdrawal request. If you already have a pending withdrawal, please wait for admin approval.');
            } finally {
                setTimeout(() => setIsSubmitting(false), 1000);
            }
        }
    };

    const handleInfoDialogClose = () => {
        setInfoDialogOpen(false);
        onClose();
        window.location.href = '/exchanges?view=crypto';
    };

    const handleMaxClick = () => {
        const maxAmount = getMaxAmount();
        setAmount(maxAmount);
        validateAmount(maxAmount);
    };

    const handleApplyReferral = async () => {
        if (!referralCode) return;
        
        try {
            const { data, error } = await supabase
                .rpc('is_valid_referral_code', {
                    input_code: referralCode.toLowerCase()
                });
            
            if (error) throw error;
            
            if (data) {
                setIsReferralApplied(true);
                setReferralError('');
            } else {
                setIsReferralApplied(false);
                setReferralError('Invalid referral code');
            }
        } catch (error) {
            console.error('Error checking referral code:', error);
            setIsReferralApplied(false);
            setReferralError('Error validating referral code');
        }
    };

    const textFieldSx = {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInputLabel-root': {
            fontSize: '1.3rem',
        },
        '& .MuiInputBase-input': {
            fontSize: '1.4rem',
            padding: '18px 16px',
        },
        '& .MuiFormHelperText-root': {
            fontSize: '1.2rem',
            marginTop: '8px',
        },
        '& .MuiOutlinedInput-root': {
            minHeight: '60px',
        }
    };

    const referralFieldSx = {
        ...textFieldSx,
        mb: 3,
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: isReferralApplied ? '#05f783' : 'white',
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isReferralApplied ? '#05f783' : 'rgba(255, 255, 255, 0.23)',
                borderWidth: isReferralApplied ? '2px' : '1px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: isReferralApplied ? '#05f783' : 'white',
            },
        }
    };

    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', sm: 400, md: 450 },
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 3,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                }}>
                    <Typography 
                        variant="h5"
                        component="h2" 
                        sx={{ 
                            mb: 3,
                            fontWeight: 'bold',
                            textAlign: 'center'
                        }}
                    >
                        {type === 'deposit' ? 'Deposit Crypto' : 'Withdraw Litecoin'}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                        <TextField
                            fullWidth
                            type="number"
                            value={amount}
                            onChange={handleAmountChange}
                            label={isUSD ? 'Amount in USD' : 'Amount in Gold tokens'}
                            variant="outlined"
                            error={!!amountError}
                            helperText={amountError}
                            sx={textFieldSx}
                            InputProps={{
                                endAdornment: type === 'withdraw' && (
                                    <Button 
                                        onClick={handleMaxClick}
                                        sx={{ 
                                            minWidth: 'auto',
                                            px: 2,
                                            mr: -1,
                                            fontSize: '1rem',
                                        }}
                                    >
                                        MAX
                                    </Button>
                                )
                            }}
                        />
                        <IconButton onClick={handleSwap} sx={{ ml: 2, p: 2 }}>
                            <SwapHorizIcon sx={{ fontSize: '2rem' }} />
                        </IconButton>
                    </Box>

                    {type === 'withdraw' && (
                        <TextField
                            fullWidth
                            value={walletAddress}
                            onChange={(e) => {
                                setWalletAddress(e.target.value);
                                if (e.target.value) validateWalletAddress(e.target.value);
                            }}
                            label="Wallet Address"
                            variant="outlined"
                            error={!!addressError}
                            helperText={addressError}
                            sx={{ ...textFieldSx, mb: 3 }}
                            placeholder="Enter your litecoin wallet address"
                        />
                    )}

                    {type === 'deposit' && (
                        <TextField
                            fullWidth
                            value={referralCode}
                            onChange={(e) => {
                                setReferralCode(e.target.value);
                                setIsReferralApplied(false);
                                setReferralError('');
                            }}
                            label="Referral Code (Optional)"
                            variant="outlined"
                            sx={referralFieldSx}
                            placeholder="Enter referral code if you have one"
                            InputProps={{
                                style: { fontSize: '1.4rem' },
                                readOnly: isReferralApplied,
                                endAdornment: (
                                    <Button 
                                        onClick={handleApplyReferral}
                                        disabled={!referralCode || isReferralApplied}
                                        variant="contained"
                                        sx={{ 
                                            height: '40px',
                                            mr: -1,
                                            fontSize: '1.4rem',
                                            minWidth: '80px',
                                            whiteSpace: 'nowrap',
                                            backgroundColor: 'transparent',
                                            color: '#05f783',
                                            '&:hover': {
                                                backgroundColor: isReferralApplied ? 'transparent' : 'primary.dark',
                                            },
                                            '&.Mui-disabled': {
                                                display: !referralCode ? 'none' : '',
                                                backgroundColor: 'transparent',
                                                color: '#05f783'
                                            },
                                            boxShadow: isReferralApplied ? 'none' : undefined
                                        }}
                                    >
                                        {isReferralApplied ? '✓' : 'Apply'}
                                    </Button>
                                )
                            }}
                            error={!!referralError}
                            helperText={referralError}
                        />
                    )}

                    <Typography variant="h6" sx={{ mb: 3, textAlign: 'center' }}>
                        {isUSD
                            ? <>${amount || '0'} USD = {calculateConversion()} <GoldCoin additionalStyles="ml-1 mb-1" style={{ width: '24px', height: '24px' }} /></>
                            : (
                                <>
                                    {amount || '0'} <GoldCoin additionalStyles="mx-1 mb-1" style={{ width: '24px', height: '24px' }} /> = ${calculateConversion()} USD
                                </>
                            )
                        }
                    </Typography>

                    {/* <div className='flex items-center justify-center mb-4'>
                        <GoldCoin additionalStyles='mr-2' style={{ width: '24px', height: '24px' }} />
                        <Typography variant="h6">
                            = $0.20
                        </Typography>
                    </div> */}

                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        mt: 4
                    }}>
                        <Button 
                            onClick={onClose}
                            sx={{ 
                                fontSize: '1.1rem',
                                px: 4,
                                py: 1.5
                            }}
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                            sx={{ 
                                fontSize: '1.1rem',
                                px: 4,
                                py: 1.5
                            }}
                        >
                            {type === 'deposit' ? 'Deposit' : 'Withdraw'}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Dialog
                open={infoDialogOpen}
                onClose={handleInfoDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        bgcolor: 'background.paper',
                        backgroundImage: `url(${background})`,
                        borderRadius: 2,
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.3)',
                        border: '8px solid black',
                        borderImage: `url(${border}) 2 stretch`,
                    }
                }}
            >
                <DialogTitle id="alert-dialog-title" sx={{ color: 'text.primary', fontWeight: 'bold', textAlign: 'center', pt: 3 }}>
                    Crypto Withdrawal Notice
                </DialogTitle>
                <DialogContent sx={{ px: 4, pb: 4 }}>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'text.secondary', textAlign: 'center' }}>
                        <Typography variant="body1" sx={{ mb: 2, fontSize: '1.2rem' }}>
                            Crypto withdrawals currently require admin approval (this may take some time, we apologize). This is a temporary measure to ensure the integrity of the system.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        className='rs-button' 
                        variant='contained' 
                        onClick={handleInfoDialogClose} 
                        sx={{ 
                            bgcolor: 'secondary.main',
                            color: 'text.primary',
                            fontWeight: 'bold',
                            px: 3,
                            '&:hover': {
                                bgcolor: 'secondary.dark',
                            }
                        }} 
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CryptoTransactionModal;