import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Tabs, Tab, Grid, Button, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../contexts/AuthContext';
import BitcoinIcon from '../../assets/btclogo.png';
import CryptoIcon from '../../assets/ltclogo.png'; // Import your crypto icon
import GoldIcon from '../../assets/gold.png'; // Import your gold icon
import RSPSIcon from '../../assets/rspslogo.png'; // Import your RSPS icon
import GoldTransactionModal from './GoldTransactionModal';
import CryptoTransactionModal from './CryptoTransactionModal';
import RSPSDepositModal from './RSPSTransactionModel'; // Import the new RSPS deposit modal
import TipModal from './TipModal';
import { GoldCoin } from '../Coins'; // Add this import

interface WalletModalProps {
    open: boolean;
    onClose: () => void;
}

const WalletModal: React.FC<WalletModalProps> = ({ open, onClose }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const { session } = useAuth();
    const username = session?.user?.id || 'Guest'; // Adjust this based on your session structure
    const [goldModalOpen, setGoldModalOpen] = useState(false);
    const [cryptoModalOpen, setCryptoModalOpen] = useState(false);
    const [tipModalOpen, setTipModalOpen] = useState(false);
    const [rspsModalOpen, setRspsModalOpen] = useState(false);
    const [transactionType, setTransactionType] = useState<'deposit' | 'withdraw'>('deposit');

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const handleOpenGoldModal = (type: 'deposit' | 'withdraw') => {
        setTransactionType(type);
        setGoldModalOpen(true);
    };

    const handleCloseGoldModal = () => {
        setGoldModalOpen(false);
    };
    const handleOpenCryptoModal = (type: 'deposit' | 'withdraw') => {
        setTransactionType(type);
        setCryptoModalOpen(true);
    };
    const handleOpenTipModal = () => {
        setTipModalOpen(true);
    };

    const handleCloseTipModal = () => {
        setTipModalOpen(false);
    };

    const handleCloseCryptoModal = () => {
        setCryptoModalOpen(false);
    };

    const handleOpenRspsModal = () => {
        setRspsModalOpen(true);
    };

    const handleCloseRspsModal = () => {
        setRspsModalOpen(false);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: { xs: '90%', sm: '80%', md: 600 },
                bgcolor: 'background.default',
                boxShadow: 24,
                p: 4,
                borderRadius: 1,
                color: 'text.primary',
                position: 'relative'
            }}>
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" sx={{ mb: 2, fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } }}>
                    Wallet
                </Typography>
                <Tabs value={tabIndex} onChange={handleTabChange} textColor="inherit" indicatorColor="primary">
                    <Tab label="Deposit" sx={{ fontWeight: '700', letterSpacing: '0.075em' }} />
                    <Tab label="Withdraw" sx={{ fontWeight: '700', letterSpacing: '0.075em' }} />
                    <Tab label="Tip" sx={{ fontWeight: '700', letterSpacing: '0.075em' }} />
                </Tabs>
                <Box sx={{ mt: 2 }}>
                    {tabIndex === 0 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                    onClick={() => handleOpenGoldModal('deposit')}
                                >
                                    <img src={GoldIcon} alt="Gold" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Gold</h3>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                    onClick={() => handleOpenCryptoModal('deposit')}
                                >
                                    <img src={CryptoIcon} alt="Crypto" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Crypto</h3>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                    onClick={handleOpenRspsModal}
                                >
                                    <img src={RSPSIcon} alt="RSPS" style={{ width: 'auto', height: '35px' }} />
                                    <h3>RSPS</h3>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 1 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                    onClick={() => handleOpenGoldModal('withdraw')}
                                >
                                    <img src={GoldIcon} alt="Gold" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Gold</h3>
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                    onClick={() => handleOpenCryptoModal('withdraw')}
                                >
                                    <img src={CryptoIcon} alt="Crypto" style={{ width: 'auto', height: '35px' }} />
                                    <h3>Litecoin</h3>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {tabIndex === 2 && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                        height: '100px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: 2
                                    }}
                                    onClick={handleOpenTipModal}
                                >   
                                    <GoldCoin style={{ height: '35px' }} />
                                    <h3>Tip Tokens</h3>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Box>
                <GoldTransactionModal
                    open={goldModalOpen}
                    onClose={handleCloseGoldModal}
                    type={transactionType}
                />
                <CryptoTransactionModal
                    open={cryptoModalOpen}
                    onClose={handleCloseCryptoModal}
                    type={transactionType}
                />
                <RSPSDepositModal
                    open={rspsModalOpen}
                    onClose={handleCloseRspsModal}
                    onCloseWalletModal={onClose}
                />
                <TipModal
                    open={tipModalOpen}
                    onClose={handleCloseTipModal}
                    onCloseWalletModal={onClose}
                />

            </Box>
        </Modal>
    );
};

export default WalletModal;