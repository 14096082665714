import React, { Component } from 'react';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";
import { GoldCoin, SilverCoin } from "../Coins";
import { debounce } from 'lodash';
import VolumeSlider from "../VolumeControl";
import SpeedControl from "../SpeedControl";
import { IoDiamond } from "react-icons/io5";
import { FaBomb } from "react-icons/fa";
import { IoRefreshOutline } from "react-icons/io5";
import Tooltip from '@mui/material/Tooltip';
import { FaTimes } from "react-icons/fa";

interface BettingWindowProps {
  signedIn: boolean;
  bet: number;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBetBlur: () => void;
  handleHalfBet: () => void;
  handleDoubleBet: () => void;
  startGame: () => void;
  gameStarted?: boolean;
  mines: number;
  handleMinesChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  multiplier: number;
  handleCashOut: () => void;
  selectedTilesLength: number;
  pickRandomTile: () => void;
  disableModifications: boolean;
  onModeChange: (mode: 'Manual' | 'Auto') => void;
  autoSelectedGridLength: number;
  resetGrid: () => void;
  numberOfGames: number;
  setNumberOfGames: (e: React.ChangeEvent<HTMLInputElement>) => void;
  instantPlay: () => void;
  startAutoPlay: () => void;
  stopAutoPlay: () => void;
  recovering: boolean;
  shake: boolean;
  balanceType: "gold" | "silver";
  gemsLeft: number;
  nextMultiplier: number;
  autoCashoutAmount: number;
  autoMultiplier: number;
  speed?: number;
  setSpeed?: Function;
}

interface BettingWindowState {
  mode: 'Manual' | 'Auto';
  isMediumOrLarger: boolean;
  isButtonDisabled: boolean;
}

class MinesBettingWindow extends Component<BettingWindowProps, BettingWindowState> {
  constructor(props: BettingWindowProps) {
    super(props);
    this.state = {
      mode: 'Manual',
      isMediumOrLarger: window.innerWidth >= 768,
      isButtonDisabled: false
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = debounce(() => { // Using lodash debounce function to limit the rate of state updates
    this.setState({ isMediumOrLarger: window.innerWidth >= 768 });
  }, 100);


  handleModeChange = (mode: 'Manual' | 'Auto') => {
    this.setState({ mode });
    this.props.onModeChange(mode);
  };

  handleStartGame = () => {
    if (this.state.isButtonDisabled) return;
    
    this.props.startGame(); // Execute immediately
    this.setState({ isButtonDisabled: true });
    
    setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 1000); // Re-enable after 1 second
  };

  render() {
    const isBetValid = /^\d*(\.\d{0,2})?$/.test(this.props.bet.toString()) && this.props.bet >= 1 && !this.props.gameStarted && !this.props.recovering;
    const isAutoValid = this.props.numberOfGames > 0 && this.props.autoSelectedGridLength > 0;
    const buttonBgClass = !isBetValid ? 'bg-gray-400 cursor-not-allowed' : '';
    const { mode } = this.state;


    return (
      <div
        className="betting-window w-[320px] pb-10"
        style={{
          border: "5px solid black",
          backgroundImage: `url(${background})`,
          borderImage: `url(${border}) 2 stretch`,
          backgroundSize: "cover",
        }}
        id="betting"
      >
        <div className="flex justify-center order-5 mt-2 md:order-1">
          <div className="toggle-container">
            <input
              type="radio"
              id="manual"
              name="mode"
              value="Manual"
              checked={mode === 'Manual'}
              onChange={() => this.handleModeChange('Manual')}
              disabled={this.props.gameStarted}
            />
            <label htmlFor="manual" className="toggle-label">Manual</label>
            <input
              type="radio"
              id="auto"
              name="mode"
              value="Auto"
              checked={mode === 'Auto'}
              onChange={() => this.handleModeChange('Auto')}
              disabled={this.props.gameStarted}
            />
            <label htmlFor="auto" className="toggle-label">Auto</label>
            <div className="toggle-slider"></div>
          </div>
        </div>
        <div className="order-1 md:order-2">
          <p>Amount</p>
          <div className="flex items-center w-full">
            <div className="relative flex w-full">
              <input
                data-testid='bet-amount'
                autoComplete="on"
                type="number"
                value={this.props.bet === 0 ? '' : this.props.bet}
                onChange={this.props.handleAmountChange}
                onBlur={this.props.handleBetBlur}
                placeholder="Enter bet"
                className={`w-full ${this.props.gameStarted ? "cursor-not-allowed" : ""}`}
                disabled={this.props.gameStarted}
              ></input>
              {(
                this.props.balanceType === "gold" ? (
                  <GoldCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
                ) : (
                  <SilverCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
                )
              )}
            </div>
            <button onClick={this.props.handleHalfBet} className="rs-button-small hidden sm:flex ml-2 px-2 pt-1" disabled={this.props.gameStarted}>
              <text>½</text>
            </button>
            <button onClick={this.props.handleDoubleBet} className="rs-button-small hidden sm:flex ml-1 px-2 py-1" disabled={this.props.gameStarted}>
              <text>2x</text>
            </button>
          </div>
        </div>
        {mode === 'Manual' ? (
          <button
            className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''} order-4 md:order-4 flex items-center justify-center`}
            onClick={this.props.gameStarted ? this.props.handleCashOut : this.handleStartGame}
            disabled={(!isBetValid || !this.props.signedIn) && (!this.props.gameStarted || this.props.selectedTilesLength === 0) || this.state.isButtonDisabled}
          >
            <span className="flex items-center">
              {this.props.gameStarted ? (
                <>
                  Cash Out
                  <span className="md:hidden ml-2 flex items-center">
                    ({(this.props.bet * this.props.multiplier).toFixed(2)}
                    {this.props.balanceType === "gold" ? (
                      <GoldCoin additionalStyles="inline ml-0.5" style={{ width: "20px", height: "20px", marginBottom: "2px" }} />
                    ) : (
                      <SilverCoin additionalStyles="inline ml-0.5" style={{ width: "20px", height: "20px", marginBottom: "2px" }} />
                    )})
                  </span>
                </>
              ) : (
                this.props.signedIn ? (this.props.balanceType === "gold" ? "Bet" : "Free Play") : "Sign In To Play"
              )}
            </span>
          </button>
        ) : (
          <>
            {this.props.gameStarted ? (
              <button
                className={`mines-start-btn ${this.props.shake ? 'shake-animation' : ''} order-[-1] md:order-4`}
                onClick={this.props.stopAutoPlay}
              >
                Stop Autobet
              </button>
            ) : (
              <button
                className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''} order-[-1] md:order-4`}
                onClick={this.props.startAutoPlay}
                disabled={!isBetValid || !isAutoValid || !this.props.signedIn}
              >
                {this.props.signedIn ? "Start Autobet" : "Sign In To Play"}
              </button>
            )}
          </>
        )}
        {!this.props.gameStarted && (
          <div className="flex flex-col items-left justify-center w-full mt-2 order-4 md:order-2">
            <p>Mines</p>
            <select
              id="risk"
              value={this.props.mines}
              onChange={this.props.handleMinesChange}
              disabled={this.props.disableModifications}
              className={`text-primary-900 p-2 rounded-lg w-full ${this.props.disableModifications ? "cursor-not-allowed" : "cursor-pointer"}`}
            >
              {[...Array(24).keys()].map(i => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
        )}
        {mode === 'Auto' && (
          <div className="flex flex-col items-left justify-center w-full mt-2 order-2 md:order-2">
            <p>Number of Games</p>
            <div className="flex items-center mt-1">
              <input
                type="number"
                placeholder="Enter number of games"
                value={this.props.numberOfGames === 0 ? '' : this.props.numberOfGames}
                onChange={this.props.setNumberOfGames}
                className={`w-2/3 text-primary-900 rounded-lg flex-grow ${this.props.gameStarted ? "cursor-not-allowed" : ""}`}
                disabled={this.props.gameStarted}
              />
              {this.props.autoSelectedGridLength > 0 ? (
                <Tooltip title="Reset Grid" arrow>
                  <button 
                    onClick={this.props.resetGrid} 
                    className="rs-button-small px-2 py-1 flex items-center justify-center ml-2"
                    aria-label="Reset Grid"
                  >
                    <IoRefreshOutline size={18} />
                  </button>
                </Tooltip>
              ) : (
                <button 
                  disabled
                  className="rs-button-small px-2 py-1 flex items-center justify-center ml-2 opacity-30 cursor-not-allowed"
                  aria-label="Reset Grid"
                >
                  <IoRefreshOutline size={18} />
                </button>
              )}
            </div>
          </div>
        )}
        {mode === 'Manual' && this.props.gameStarted && (
          <div className="flex justify-between mt-2 order-2 md:order-2">
            <div className="w-2/3 pr-1 hidden md:block">
              <p className="text-sm">Cashout Amount</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{(this.props.bet * this.props.multiplier).toFixed(2)}</p>
                {(
                  this.props.balanceType === "gold" ? (
                    <GoldCoin additionalStyles="ml-2" style={{ width: "20px", height: "20px" }} />
                  ) : (
                    <SilverCoin additionalStyles="ml-2" style={{ width: "20px", height: "20px" }} />
                  )
                )}
              </div>
            </div>
            <div className="w-1/3 pl-1 hidden md:block">
              <p className="text-sm">Next Tile</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{this.props.multiplier > 1 ? this.props.nextMultiplier : '-'}</p>
                <span className="text-yellow-400 ml-1 text-lg">✕</span>
              </div>
            </div>
          </div>
        )}
        {mode === 'Auto' && (
          <div className="flex justify-between mt-2 order-2 md:order-2">
            <div className="w-2/3 pr-1">
              <p className="text-sm">Potential Winnings</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{this.props.autoCashoutAmount}</p>
                {(
                  this.props.balanceType === "gold" ? (
                    <GoldCoin additionalStyles="ml-2" style={{ width: "20px", height: "20px" }} />
                  ) : (
                    <SilverCoin additionalStyles="ml-2" style={{ width: "20px", height: "20px" }} />
                  )
                )}
              </div>
            </div>
            <div className="w-1/3 pl-1">
              <p className="text-sm">Multiplier</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{this.props.autoMultiplier}</p>
                <span className="text-yellow-400 ml-1 text-lg">✕</span>
              </div>
            </div>
          </div>
        )}
        {this.props.gameStarted && mode === 'Manual' && (
          <div className="flex justify-between mt-2 order-2 md:order-2">
            <div className="w-1/3 pr-1">
              <p className="text-sm">Gems Left</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{this.props.gemsLeft}</p>
                <IoDiamond className="mines-diamond-icon ml-1 text-lg" />
              </div>
            </div>
            <div className="w-1/3 px-1">
              <p className="text-sm">Mines Left</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{this.props.mines}</p>
                <FaBomb className="mines-mine-icon ml-1 text-lg align-middle mb-1" />
              </div>
            </div>
            <div className="w-1/3 pr-1">
              <p className="text-sm">Multiplier</p>
              <div className="bg-[#2c2c2c] p-2 rounded flex items-center justify-center">
                <p className="text-lg">{this.props.multiplier}</p>
                <span className="text-yellow-400 ml-1 text-lg">✕</span>
              </div>
            </div>
          </div>
        )}
        {mode === 'Manual' && this.props.gameStarted && (
          <button className="mines-random-tile-btn order-3 md:order-3 flex items-center justify-center" onClick={this.props.pickRandomTile}>
            <span className="flex items-center">
              Pick random tile
              <span className="md:hidden ml-2 flex items-center justify-center">
                ({this.props.multiplier > 1 ? this.props.nextMultiplier : '-'}
                <FaTimes className="text-yellow-400" style={{ fontSize: "0.8em"}} />)
              </span>
            </span>
          </button>
        )}
        <VolumeSlider gameId="mines" disableModification={this.props.disableModifications} additionalStyles="absolute bottom-2.5 left-2.5" />
        {mode === 'Auto' && <SpeedControl gameId="mines" disableModification={this.props.disableModifications} speed={this.props.speed} setSpeed={this.props.setSpeed} additionalStyles="absolute bottom-2.5 right-2.5"/>}
      </div>
    );
  }
}

export default MinesBettingWindow;