import React from 'react';
import { Box, Typography, Button, Paper } from '@mui/material';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext.js';
import { useBalance } from '../../contexts/BalanceContext.js';
import { GoldCoin } from '../Coins';

interface Challenge {
    id: number;
    description: string;
    minimum_bet: number;
    reward: number;
    expiry: string;
    completed: boolean;
    completed_by: string | null;
}

interface DailyChallengeProps {
    challenge: Challenge;
}

const DailyChallenge: React.FC<DailyChallengeProps> = ({ challenge }) => {
    const { session } = useAuth();

    const formatCountdown = (expiry: string) => {
        const now = new Date();
        const timeLeft = new Date(expiry).getTime() - now.getTime();
        if (timeLeft <= 0) return "Expired";
        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        return `${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <Paper
            elevation={3}
            sx={{
                p: 3,
                mb: 3,
                mt: 3,
                width: '100%',
                maxWidth: 500,
                background: 'linear-gradient(145deg, hsla(24, 12.82%, 20%, 100%), hsla(24, 12.82%, 15%, 100%))',
                borderRadius: '12px',
                boxShadow: `
                    0 4px 20px rgba(0, 0, 0, 0.5),
                    0 0 20px rgba(245, 166, 35, 0.15),
                    inset 0 0 20px rgba(245, 166, 35, 0.05)
                `,
                border: '1px solid rgba(255, 255, 255, 0.1)',
            }}
        >
            <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{
                    fontSize: '1.6rem',
                    fontWeight: 600,
                    color: '#f5a623',
                    mb: 2,
                }}
            >
                🎯 Daily Challenge
            </Typography>
            <Typography
                variant="h6"
                align="center"
                sx={{
                    fontSize: '1.8rem',
                    color: '#ffffff',
                    mb: 2,
                    fontWeight: 500,
                }}
            >
                {challenge.description}
            </Typography>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                gap: 4, 
                mb: 2 
            }}>
                {challenge.minimum_bet > 0 && (
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: '1.4rem',
                        color: '#ccc',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    Minimum Bet: 
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: '#05f783', fontSize: '1.6rem', fontWeight: 600 }}>{challenge.minimum_bet}</span>
                        <GoldCoin style={{ width: '24px', height: '24px', margin: '0 4px' }} />
                        </Box>
                    </Typography>
                )}
                {challenge.reward > 0 && (
                    <Typography
                        variant="body1"
                    sx={{
                        fontSize: '1.4rem',
                        color: '#ccc',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                    }}
                >
                    Reward: 
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ color: '#05f783', fontSize: '1.6rem', fontWeight: 600 }}>{challenge.reward}</span>
                            <GoldCoin style={{ width: '24px', height: '24px', margin: '0 4px' }} />
                        </Box>
                    </Typography>
                )}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 1,
                mb: 2,
            }}>
                {challenge.reward > 0 && (
                <Typography
                    variant="h6"
                    sx={{
                        fontSize: '1.6rem',
                        fontWeight: 600,
                        color: '#05f783',
                    }}
                >
                    {challenge.completed ? (
                        <>
                            Completed by{' '}
                            <span style={{ 
                                color: '#FFD700',
                                textShadow: '0 0 8px rgba(255, 215, 0, 0.5)',
                                fontWeight: 700,
                                letterSpacing: '0.5px'
                            }}>
                                {challenge.completed_by}
                            </span>
                        </>
                    ) : `Active`}
                </Typography>
                )}
                <Typography
                    variant="body1"
                    sx={{
                        fontSize: '1.4rem',
                        color: '#999',
                        letterSpacing: '0.5px',
                        '& span': {
                            color: '#fff',
                            fontWeight: 500,
                        }
                    }}
                >
                    {challenge.completed ? 'Next challenge in: ' : 'Expires in: '}
                    <span>{formatCountdown(challenge.expiry)}</span>
                </Typography>
            </Box>
        </Paper>
    );
};

export default DailyChallenge;
