import React, { Component, useState } from "react";
import { debounce } from "lodash";
import { useAuth } from "../../contexts/AuthContext";
import background from "../../assets/background.png";
import border from "../../assets/borders.png";
import WinningsComponent from "../GenericWinningsComponent";
import withFadeInDelay from "../withFadeInDelay";
import { GoldCoin, SilverCoin } from "../Coins";
import VolumeSlider from "../VolumeControl";
import SpeedControl from "../SpeedControl";

const FadedInWinningsComponent = withFadeInDelay(WinningsComponent, 500);

interface BettingWindowProps {
  bet: number;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBetBlur: () => void;
  handleHalfBet: () => void;
  handleDoubleBet: () => void;
  tripsBet: number;
  handleTripsChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleTripsBetBlur: () => void;
  handleHalfTripsBet: () => void;
  handleDoubleTripsBet: () => void;
  startGame: Function;
  signedIn?: boolean;
  gameStarted?: boolean;
  element?: any;
  shake?: boolean;
  balanceType?: string;
  speed?: number;
  setSpeed?: Function;
}

interface BettingWindowState {
  isMediumOrLarger: boolean;
  isButtonDisabled: boolean;
}

class TexasBettingWindow extends Component<BettingWindowProps, BettingWindowState> {
  constructor(props: BettingWindowProps) {
    super(props);
    this.state = {
      isMediumOrLarger: window.innerWidth >= 768,
      isButtonDisabled: false
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = debounce(() => { // Using lodash debounce function to limit the rate of state updates
    this.setState({ isMediumOrLarger: window.innerWidth >= 768 });
  }, 100);

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[\d.]/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  handleStartGame = () => {
    if (this.state.isButtonDisabled) return;
    
    this.props.startGame(); // Execute immediately
    this.setState({ isButtonDisabled: true });
    
    setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, 1000); // Re-enable after 1 second
  };

  render() {
    const isBetValid =
      /^\d*(\.\d{0,2})?$/.test(this.props.bet.toString()) &&
      this.props.bet >= 1 &&
      (this.props.tripsBet >= 0 &&
        /^\d*(\.\d{0,2})?$/.test(this.props.tripsBet.toString())) &&
      !this.props.gameStarted;
    const buttonBgClass = !isBetValid || !this.props.signedIn
      ? "bg-disabled"
      : "bg-enabled hover:bg-enabled";

    return (
      <div
        className="betting-window pb-10"
        style={{
          border: "5px solid black",
          backgroundImage: `url(${background})`,
          borderImage: `url(${border}) 2 stretch`,
          backgroundSize: "cover",
        }}
        id="betting"
      >
        <p>
          Ante
        </p>
        <div className="flex items-center w-full">
          <div className="relative flex w-full">
            <input
              data-testid="bet-amount"
              autoComplete="on"
              type="number"
              defaultValue="1"
              placeholder="Enter bet"
              value={this.props.bet === 0 ? "" : this.props.bet}
              onChange={this.props.handleAmountChange}
              onBlur={this.props.handleBetBlur}
              onKeyDown={this.handleKeyPress}
              min="1"
              max="1000"
              disabled={this.props.gameStarted}
              className=""
            ></input>
            {this.state.isMediumOrLarger && (
              this.props.balanceType === "gold" ? (
                <GoldCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
              ) : (
                <SilverCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
              )
            )}
          </div>
          <button
            onClick={this.props.handleHalfBet}
            className="rs-button-small flex ml-2 px-2 pt-1">
            <text>½</text>
          </button>

          <button
            onClick={this.props.handleDoubleBet}
            className="rs-button-small flex ml-1 px-2 py-1">
            <text>2x</text>
          </button>
        </div>

        <p>
          Blind {window.innerWidth >= 768 ? "" : this.props.bet}
        </p>
        <div className="flex items-center w-full">
          <div className="relative flex w-full">
            <input
              type="number"
              value={this.props.bet === 0 ? "" : this.props.bet}
              onChange={this.props.handleAmountChange}
              onBlur={this.props.handleBetBlur}
              min="0"
              max="1000"
              disabled={true}
              className="bg-neutral-500 hover:bg-neutral-500 cursor-not-allowed"
            ></input>
            {this.state.isMediumOrLarger && (
              this.props.balanceType === "gold" ? (
                <GoldCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
              ) : (
                <SilverCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
              )
            )}
          </div>
        </div>
        <p>
          Side Bet: Trips
        </p>
        <div className="flex items-center w-full">
          <div className="relative flex w-full">
            <input
              type="number"
              value={this.props.tripsBet === 0 ? "" : this.props.tripsBet}
              onChange={this.props.handleTripsChange}
              onBlur={this.props.handleTripsBetBlur}
              onKeyDown={this.handleKeyPress}
              disabled={this.props.gameStarted}
              min="1"
              className=""
            ></input>
            {this.state.isMediumOrLarger && (
              this.props.balanceType === "gold" ? (
                <GoldCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
              ) : (
                <SilverCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
              )
            )}
          </div>
          <button
            onClick={this.props.handleHalfTripsBet}
            className="rs-button-small flex ml-2 px-2 pt-1">
            <text>½</text>
          </button>
          <button
            onClick={this.props.handleDoubleTripsBet}
            className="rs-button-small flex ml-1 px-2 py-1">
            <text>2x</text>
          </button>
        </div>
        <h1 className="hidden md:block text-gray-200 text-2xl font-bold">
          Total: {(Number(this.props.bet) * 2 + Number(this.props.tripsBet)).toFixed(2)}
        </h1>
        <button
          data-testid="play-button"
          onClick={this.handleStartGame}
          disabled={!isBetValid || !this.props.signedIn || this.props.gameStarted || this.state.isButtonDisabled}
          className={`order-[-1] md:order-none mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''}`}
        >
          {this.props.signedIn ? this.props.balanceType === "gold" ? "Bet" : "Free Play" : "Sign In To Play"}
        </button>
        {this.props.element}
        <VolumeSlider gameId="ultimatetexas" disableModification={this.props.gameStarted} additionalStyles="absolute bottom-2.5 left-2.5" />
        <SpeedControl gameId="ultimatetexas" disableModification={this.props.gameStarted} speed={this.props.speed} setSpeed={this.props.setSpeed} additionalStyles="absolute bottom-2.5 right-2.5"/>
      </div>
    );
  }
}

export default TexasBettingWindow;