import { Box, CircularProgress } from '@mui/material';
import logoImage from '../assets/newlogomaybe2.png';

export default function LoadingScreen() {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'hsla(24, 12.82%, 10%, 100%)',
        gap: 3,
        zIndex: 9999,
        minHeight: '100%',
        mt: 16
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '200px',
          height: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Single spinning circle */}
        <CircularProgress
          size={200}
          thickness={2}
          sx={{
            position: 'absolute',
            color: '#f5a623',
          }}
        />

        {/* Static logo - only setting width to maintain aspect ratio */}
        <Box
          component="img"
          src={logoImage}
          alt="Logo"
          sx={{
            width: '120px',
            height: 'auto', // This will maintain the aspect ratio
          }}
        />
      </Box>

      <Box
        sx={{
          color: '#ffffff',
          fontSize: '1.2rem',
          letterSpacing: '2px',
        }}
      >
        LOADING...
      </Box>
    </Box>
  );
}
