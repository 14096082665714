import React, { Component } from "react";
import { debounce } from "lodash";
import SingleBet from './Ultimate-Texas/SingleBet';
import { useBalance } from "../contexts/BalanceContext";
import { GoldCoin, SilverCoin } from "./Coins";
import GenericSingleBet from "./GenericSingleBet";
import background from "../assets/background.png";
import border from "../assets/borders.png";
import WinningsComponent from "./GenericWinningsComponent";
import withFadeInDelay from "./withFadeInDelay";
import VolumeSlider from "./VolumeControl";
import SpeedControl from "./SpeedControl";
import { Tooltip, IconButton, Typography, Box, Button } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LoginModal from "./LoginModal"; // Import LoginModal

const FadedInWinningsComponent = withFadeInDelay(WinningsComponent, 500);


interface Bet {
  name: string;
  bet: number;
  handleAmountChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleHalfBet?: () => void;
  handleDoubleBet?: () => void;
  valid: boolean;
  winnings?: number;
  anteInfo?: boolean;
  sideBet?: boolean;
  winState?: string;
  push?: boolean;
  handleBlur: () => void;
  buttonDelay?: boolean;
}

interface BettingWindowProps {
  bets: Bet[];
  startGame: Function;
  signedIn?: boolean;
  guest?: boolean;
  gameStarted?: boolean;
  gameOver?: boolean;
  showChips?: boolean;
  autoPlay?: boolean;
  numberOfGames?: number;
  onModeChange?: (mode: 'Manual' | 'Auto') => void;
  setNumberOfGames?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  startAutoPlay?: () => void;
  stopAutoPlay?: () => void;
  autoBetTimeOut?: boolean;
  shake?: boolean;
  balanceType?: string;
  gameId?: string;
  speed?: number;
  setSpeed?: Function;
  betDelay?: number;
}

interface BettingWindowState {
  mode: 'Manual' | 'Auto';
  isMediumOrLarger: boolean;
  isRegisterModalOpen: boolean; // Add this line
  isButtonDisabled: boolean;
}

class GeneralBettingWindow extends Component<BettingWindowProps, BettingWindowState> {
  constructor(props: BettingWindowProps) {
    super(props);
    this.state = {
      mode: 'Manual',
      isMediumOrLarger: window.innerWidth >= 768,
      isRegisterModalOpen: false, // Add this line
      isButtonDisabled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = debounce(() => { // Using lodash debounce function to limit the rate of state updates
    this.setState({ isMediumOrLarger: window.innerWidth >= 768 });
  }, 100);

  handleModeChange = (mode: 'Manual' | 'Auto') => {
    if (!this.props.autoPlay) return;
    this.setState({ mode });
    this.props.onModeChange(mode);
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[\d.]/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  isBetValid = (bet: number) => {
    return /^\d*(\.\d{0,2})?$/.test(bet.toString());
  };

  handleRegisterModalOpen = () => {
    this.setState({ isRegisterModalOpen: true });
  };

  handleRegisterModalClose = () => {
    this.setState({ isRegisterModalOpen: false });
  };

  handleStartGame = () => {
    if (this.state.isButtonDisabled) return;
    
    this.props.startGame(); // Execute immediately
    this.setState({ isButtonDisabled: true });
    
    setTimeout(() => {
      this.setState({ isButtonDisabled: false });
    }, this.props.betDelay || 1000); // Use provided delay or default to 1 second
  };

  render() {
    const { bets, startGame, signedIn, gameStarted, gameOver, showChips } = this.props;
    const { mode } = this.state;
    const totalWinnings = bets.reduce((acc, bet) => acc + (bet.winnings || 0), 0);
    let allBetsValid = bets.every(
      (bet) => this.isBetValid(bet.bet) && bet.valid && !gameStarted
    );
    const isAutoValid = this.props.numberOfGames > 0;
    const disableModificationsClass = this.props.gameStarted ? "cursor-not-allowed" : "cursor-pointer";

    const buttonBgClass =
      !allBetsValid || (!signedIn && !this.props.guest)
        ? "bg-disabled"
        : "";

    return (
      <div
        className="betting-window relative pb-10"
        style={{ 
          border: "5px solid black",
          backgroundImage: `url(${background})`,
          borderImage: `url(${border}) 2 stretch`,
          backgroundSize: "cover",
        }}
        id="betting"
      >
        {this.props.autoPlay && (
          <div className="flex justify-center order-5 md:order-none">
            <div className="toggle-container">
              <input
                type="radio"
                id="manual"
                name="mode"
                value="Manual"
                checked={mode === 'Manual'}
                onChange={() => this.handleModeChange('Manual')}
                disabled={this.props.gameStarted}
              />
              <label htmlFor="manual" className={`toggle-label ${disableModificationsClass}`}>Manual</label>
              <input
                type="radio"
                id="auto"
                name="mode"
                value="Auto"
                checked={mode === 'Auto'}
                onChange={() => this.handleModeChange('Auto')}
                disabled={this.props.gameStarted}
              />
              <label htmlFor="auto" className={`toggle-label ${disableModificationsClass}`}>Auto</label>
              <div className="toggle-slider"></div>
            </div>
          </div>)}
        {bets.map((bet, index) => (
          <div className="mb-2">
            <p>
              {(bet.sideBet ? " Side Bet: " : "") + bet.name}
            </p>
            <div className="flex items-center w-full">
              <div className="relative flex w-full">
                <input
                  data-testid={`bet-amount-${index}`}
                  autoComplete="on"
                  type="number"
                  value={bet.bet === 0 ? "" : bet.bet}
                  onChange={bet.handleAmountChange}
                  onBlur={bet.handleBlur}
                  onKeyDown={this.handleKeyPress}
                  min="1"
                  max="1000"
                  step="0.01"
                  placeholder={bet.sideBet ? "" : "Enter bet"}
                  disabled={this.props.gameStarted}
                  className={`relative w-full ${disableModificationsClass}`}
                />
                {this.state.isMediumOrLarger && (
                  this.props.balanceType === "gold" ? (
                    <GoldCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
                  ) : (
                    <SilverCoin additionalStyles="absolute right-2 pointer-events-none mt-0.5" />
                  )
                )}
              </div>
              {bet.handleHalfBet && (
                <button
                  onClick={bet.handleHalfBet}
                  className={`rs-button-small flex ml-2 px-2 pt-1 ${disableModificationsClass}`}
                  disabled={this.props.gameStarted}>
                  <text>½</text>
                </button>
              )}
              {bet.handleDoubleBet && (
                <button
                  onClick={bet.handleDoubleBet}
                  className={`rs-button-small flex ml-2 px-2 pt-1 ${disableModificationsClass}`}
                  disabled={this.props.gameStarted}>
                  <text>2x</text>
                </button>
              )}
            </div>
          </div>
        ))}
        {mode === 'Auto' && (
          <div className="flex flex-col items-left justify-center w-full mb-2">
            <p>Number of Games</p>
            <input
              type="number"
              placeholder="Enter number of games"
              value={this.props.numberOfGames === 0 ? '' : this.props.numberOfGames}
              onChange={this.props.setNumberOfGames}
              className={`text-primary-900 rounded-lg w-full ${disableModificationsClass}`}
              disabled={this.props.gameStarted}
            />
          </div>
        )}
        {mode === 'Manual' ? (
          <div className="order-[-1] md:order-none flex items-center">
            <button
              data-testid="play-button"
              onClick={this.handleStartGame}
              disabled={!allBetsValid || (!this.props.signedIn && !this.props.guest) || this.props.gameStarted || this.state.isButtonDisabled}
              className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''}`}
            >
              {this.props.signedIn ? this.props.balanceType === "gold" ? "Bet" : "Free Play" : this.props.guest ? "Play As Guest" : "Sign In To Play"}
            </button>
            {this.props.guest && (
              <Tooltip
                title={
                  <Box sx={{ p: 1 }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Playing as a Guest
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 2 }}>
                      Create an account to save your progress, compete on leaderboards, and deposit or withdraw funds.
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        onClick={this.handleRegisterModalOpen}
                        sx={{
                          backgroundColor: "hsla(204, 19.23%, 45%, 100%)",
                          "&:hover": {
                            backgroundColor: "hsla(204, 19.23%, 55%, 100%)",
                          },
                        }}
                      >
                        <span className="font-bold">Register</span>
                      </Button>
                    </Box>
                  </Box>
                }
                arrow
                placement="bottom-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'hsla(24, 12.82%, 30%, 99%)', // Change this to your desired background color
                      '& .MuiTooltip-arrow': {
                        color: 'hsla(24, 12.82%, 30%, 99%)', // Make sure the arrow matches the background
                      },
                    },
                  },
                }}
                sx={{
                  color: 'text.primary',
                  boxShadow: 24,
                  fontSize: '0.875rem',
                }}
              >
                <IconButton size="small" sx={{ color: 'hsla(204, 19.23%, 60%, 100%)', ml: 1 }}>
                  <InfoOutlinedIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        ) : (
          <div className="order-[-1] md:order-none">
            {this.props.gameStarted ? (
              <button
                data-testid="play-button"
                onClick={this.props.stopAutoPlay}
                className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''}`}
                disabled={false}
              >
                Stop Autobet
              </button>
            ) : (
              <button
                data-testid="play-button"
                onClick={this.props.startAutoPlay}
                disabled={!allBetsValid || !isAutoValid || (!this.props.signedIn && !this.props.guest) || this.props.gameStarted || (this.props.autoBetTimeOut && this.props.autoBetTimeOut===true)}
                className={`mines-start-btn ${buttonBgClass} ${this.props.shake ? 'shake-animation' : ''}`}
              >
                {this.props.signedIn ? "Start Autobet" : this.props.guest ? "Play As Guest" : "Sign In To Play"}
              </button>
            )}
          </div>
        )}
        <div className="order-5 md:order-1 w-full"></div>

        {showChips && (
          <div className="hidden md:block flex w-full mt-4 mb-4 pl-3 pr-3 items-center justify-center flex-wrap">
            {bets.reduce((acc, bet, index) => {
              if (index % 2 === 0) {
                acc.push([bet]);
              } else {
                acc[acc.length - 1].push(bet);
              }
              return acc;
            }, []).map((betPair, pairIndex) => (
              <div key={pairIndex} className="flex w-full justify-center mb-4">
                {betPair.map((bet, betIndex) => (
                  <GenericSingleBet
                    key={betIndex}
                    name={bet.name}
                    bet={bet.bet}
                    gameOver={gameOver ? gameOver : false}
                    winnings={bet.winnings ? bet.winnings : 0}
                    margin="ml-6 mr-6"
                    anteInfo={typeof (bet.anteInfo) === "undefined" ? true : bet.anteInfo}
                    winState={bet.winState ? bet.winState : ""}
                    push={bet.push ? bet.push : false}
                  />
                ))}
              </div>
            ))}
          </div>
        )}
        {/* {!this.state.isMediumOrLarger && (
          <FadedInWinningsComponent
            key={gameOver}
            winnings={bets.reduce((acc, bet) => acc + (bet.winnings || 0), 0)}
            push={false}
            gameOver={gameOver}
            shouldPlaySound={false} />
        )} */}
        <VolumeSlider gameId={this.props.gameId} disableModification={this.props.gameStarted} additionalStyles="absolute bottom-2.5 left-2.5"/>
        {this.props.speed && <SpeedControl key={this.props.gameId} gameId={this.props.gameId} disableModification={this.props.gameStarted} speed={this.props.speed} setSpeed={this.props.setSpeed} additionalStyles="absolute bottom-2.5 right-2.5"/>}
        <LoginModal 
          open={this.state.isRegisterModalOpen} 
          handleClose={this.handleRegisterModalClose} 
          isRegisterView={true}
        />
      </div>
    );
  }
}

export default GeneralBettingWindow;