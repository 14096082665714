import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete } from '@mui/material';
import { GoldCoin } from '../Coins';
import { useAuth } from '../../contexts/AuthContext';
import { useMisc } from '../../contexts/MiscContext';
import { useBalance } from '../../contexts/BalanceContext';
import { toast } from 'react-toastify';
import { supabase } from '../../api/supabaseClient';

interface TipModalProps {
    open: boolean;
    onClose: () => void;
    onCloseWalletModal: () => void;
}

const TipModal: React.FC<TipModalProps> = ({ open, onClose, onCloseWalletModal }) => {
    const [recipient, setRecipient] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState('');
    const { session } = useAuth();
    const { fetchBalances } = useBalance();
    const { userList } = useMisc();
    const [isSending, setIsSending] = useState(false);

    const textFieldSx = {
        '& label.Mui-focused': {
            color: 'white',
        },
    };

    const handleSubmit = async () => {
        if (isSending) return;
        setIsSending(true);
        
        try {
            const { error } = await supabase.rpc('tip_player', {
                v_player_id: session.user.id,
                v_amount: parseFloat(amount),
                v_recipient: recipient
            });

            if (error) throw error;

            toast.success('Tip sent successfully!');
            await fetchBalances();
            onClose();
            onCloseWalletModal();
        } catch (error) {
            console.error('Error sending tip:', error);
            toast.error(error.message);
        } finally {
            setTimeout(() => setIsSending(false), 1000);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Tip Tokens
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Autocomplete
                        fullWidth
                        value={recipient}
                        onChange={(_, newValue) => setRecipient(newValue?.username || '')}
                        inputValue={recipient}
                        onInputChange={(_, newInputValue) => setRecipient(newInputValue)}
                        options={recipient ? userList.filter(user => 
                            user.username.toLowerCase().includes(recipient.toLowerCase())
                        ) : []}
                        getOptionLabel={(option) => typeof option === 'string' ? option : option.username}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Recipient Username"
                                variant="outlined"
                                error={!!error}
                                helperText={error}
                                sx={textFieldSx}
                            />
                        )}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <TextField
                        fullWidth
                        type="number"
                        value={amount}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === '') {
                                setAmount('');
                            } else {
                                const numValue = Number(value);
                                if (!isNaN(numValue) && numValue > 0) {
                                    setAmount(value);
                                }
                            }
                        }}
                        onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') {
                                e.preventDefault();
                            }
                        }}
                        inputProps={{ min: "0", step: "1" }}
                        label="Amount in Tokens"
                        variant="outlined"
                        error={!!error}
                        helperText={error}
                        sx={textFieldSx}
                    />
                </Box>
                <Typography variant="body1" sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {amount || '0'} <GoldCoin additionalStyles="mx-1 mb-1" style={{ width: '20px', height: '20px' }} /> Tokens
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button 
                        variant="contained" 
                        onClick={handleSubmit}
                        disabled={isSending}
                    >
                        Send Tip
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default TipModal;