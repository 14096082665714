import React from 'react';
import background from "../../assets/background.png";
import border from "../../assets/borders.png";

const TermsOfService = () => {
  return (
    <div className="min-h-screen p-4 md:p-12 bg-primary-900">
      <div className="max-w-4xl mx-auto rounded-lg" style={{
        border: "5px",
        borderImage: `url(${border}) 2 stretch`,
        backgroundColor: 'hsla(24, 12.82%, 30%, 100%)',
        padding: '2.5rem'
      }}>
        <h1 className="text-4xl font-runescape text-yellow-500 mb-12 text-center tracking-wider">Terms of Service</h1>

        {/* Bug Reports Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">1. Bug Reports and Exploits</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              We encourage users to report any bugs or exploits discovered on our platform. Exploiting bugs for personal gain,
              including but not limited to attempts to withdraw funds without notifying us of the bug or reporting it, is strictly prohibited.
            </p>
            <p className="text-red-500 font-semibold">
              If we determine that you intentionally abused a bug without reporting it or otherwise informing us,
              this may result in the permanent suspension of your account. Furthermore, you will not be eligible to
              recover any funds associated with your account at the time of the violation.
            </p>
            <p className="text-gray-300">
              By using our platform, you acknowledge and agree to these terms and understand the consequences of failing to report exploits.
            </p>
          </div>
        </section>

        {/* About Platform Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">2. About the Platform</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              We are a RuneScape-themed gambling site offering users the ability to wager and play using RuneScape GP (in-game currency)
              and other forms of value. Our preferred deposit and withdrawal routes are:
            </p>
            <ul className="list-none space-y-3 pl-6 text-gray-300">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                RuneScape GP → RuneScape GP
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Cryptocurrency → RuneScape GP
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                RuneScape GP → Cryptocurrency
              </li>
            </ul>
            <p className="text-gray-300">
              RuneScape GP does not have any inherent real-world value. The value we assign to RuneScape GP is solely
              determined by the platform and may vary based on market conditions or other factors.
            </p>
            <p className="text-gray-300 font-semibold border-l-4 border-yellow-500 pl-4">
              We are in no way affiliated with or endorsed by Jagex, the creators of RuneScape. All trademarks and
              intellectual property rights for RuneScape belong to Jagex Ltd.
            </p>
          </div>
        </section>

        {/* RWT Policy Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">3. RWT (Real World Trading) Policy</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              Real World Trading (RWT) involving our platform is strictly prohibited, except as explicitly allowed within our platform rules.
              Engaging in or facilitating RWT activities outside our approved deposit/withdrawal methods may result in:
            </p>
            <ul className="list-none space-y-3 pl-6">
              <li className="flex items-center before:content-['•'] before:text-red-500 before:mr-3 before:text-xl text-red-500 font-semibold">
                Immediate account suspension
              </li>
              <li className="flex items-center before:content-['•'] before:text-red-500 before:mr-3 before:text-xl text-red-500 font-semibold">
                Permanent bans
              </li>
              <li className="flex items-center before:content-['•'] before:text-red-500 before:mr-3 before:text-xl text-red-500 font-semibold">
                Forfeiture of funds
              </li>
            </ul>
            <p className="text-gray-300">
              By using our platform, you agree not to participate in RWT in any form other than those specifically permitted by us.
              Violations of this policy will be handled at our sole discretion.
            </p>
          </div>
        </section>

        {/* Eligibility Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">4. Eligibility and Acknowledgment</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">By accessing or using our platform, you confirm that:</p>
            <ul className="list-none space-y-3 pl-6 text-gray-300">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                You are at least 18 years old.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                You agree to these Terms of Service and comply with all applicable laws and regulations.
              </li>
            </ul>
            <p className="text-gray-300 border-l-4 border-yellow-500 pl-4">
              If you disagree with any part of these Terms, you must stop using the platform immediately.
            </p>
          </div>
        </section>
        {/* Account Responsibilities Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">5. Account Responsibilities</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <ul className="list-none space-y-3 pl-6 text-gray-300">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Users are responsible for safeguarding their account credentials.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Sharing or transferring accounts is prohibited.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Users must notify us immediately of any unauthorized access or suspected breaches.
              </li>
            </ul>
            <p className="text-red-500 font-semibold border-l-4 border-red-500 pl-4">
              Failure to comply may result in suspension or termination of your account.
            </p>
          </div>
        </section>

        {/* Payments and Tokens Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">6. Payments and Tokens</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <ul className="list-none space-y-3 pl-6 text-gray-300">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                All token purchases are final and non-refundable, except as required by law.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Tokens hold <span className="font-bold">no fixed real-world monetary value</span> and cannot be exchanged outside the platform.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Tokens can be exchanged for RuneScape GP in accordance with platform rules.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Users agree to accept all price variations in token-to-GP exchange rates as part of their platform usage.
              </li>
            </ul>
          </div>
        </section>

        {/* Token and GP Pricing Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">7. Token and GP Pricing</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              The value of tokens and RuneScape GP on the platform is dynamic and may fluctuate based on real-time market conditions.
              Users acknowledge and agree to the following:
            </p>
            <ul className="list-none space-y-3 pl-6 text-gray-300">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                RuneScape GP does not have inherent real-world value.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                The value of RuneScape GP and tokens is determined solely by the platform and may change at any time.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                If the market value of GP changes between deposit and withdrawal, the user is responsible for understanding
                these fluctuations and accepts that we will use the current market rate.
              </li>
            </ul>
          </div>
        </section>

        {/* Termination Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">8. Termination of Service</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              We reserve the right to suspend or terminate accounts at any time for violations of these Terms or any other reason
              deemed necessary. Upon termination:
            </p>
            <ul className="list-none space-y-3 pl-6">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl text-gray-300">
                All rights to access the platform and its services are revoked.
              </li>
              <li className="flex items-center before:content-['•'] before:text-red-500 before:mr-3 before:text-xl text-red-500 font-semibold">
                No funds or account balances will be recoverable in cases of intentional violations of these Terms.
              </li>
            </ul>
          </div>
        </section>

        {/* Prohibited Conduct Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">9. Prohibited Conduct</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">Users agree not to:</p>
            <ul className="list-none space-y-3 pl-6 text-gray-300">
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Use the platform for illegal or unauthorized purposes.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Exploit bugs, glitches, or errors.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Engage in abusive, harmful, or threatening behavior toward staff or other users.
              </li>
              <li className="flex items-center before:content-['•'] before:text-yellow-500 before:mr-3 before:text-xl">
                Participate in RWT outside approved methods.
              </li>
            </ul>
            <p className="text-red-500 font-semibold border-l-4 border-red-500 pl-4">
              Violations may result in account suspension, termination, or permanent bans.
            </p>
          </div>
        </section>

        {/* Disclaimer Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">10. Disclaimer of Liability</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              The platform is provided "as is" and "as available." We make no guarantees about its reliability, availability,
              or functionality. We are not liable for any losses, damages, or disruptions resulting from your use of the platform.
            </p>
          </div>
        </section>

        {/* Amendments Section */}
        <section className="mb-12">
          <h2 className="text-2xl font-runescape text-yellow-500 mb-6 tracking-wide">11. Amendments to Terms</h2>
          <div className="space-y-6 font-[Runescape] text-lg leading-relaxed">
            <p className="text-gray-300">
              These Terms may be updated periodically. Continued use of the platform after changes implies acceptance of the
              revised Terms. Users are encouraged to review these Terms regularly for updates.
            </p>
          </div>
        </section>

      </div>
    </div>
  );
};

export default TermsOfService;