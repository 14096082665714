import React, { useState, useEffect } from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Divider,
  IconButton,
  AppBarProps,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  useMediaQuery,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Ensure this is imported
import StyleIcon from "@mui/icons-material/Style";
import { ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { GiBatteredAxe, GiCardAceHearts, GiCartwheel, GiDaisy, GiWhiteTower } from "react-icons/gi";
import { LuSwords } from "react-icons/lu";
import { Link } from "react-router-dom";
import LoginModal from "../LoginModal";
import { useAuth } from "../../contexts/AuthContext";
import { useBalance } from "../../contexts/BalanceContext";
import LeftSideBarIcon from "./LeftSideBarIcon";
import background from "../../assets/background.png";
import background_brighter from "../../assets/background_brighter.png";
import { IoFlowerSharp, IoDiamond } from "react-icons/io5";
import { FaHockeyPuck, FaDice, FaPlus, FaDiscord } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // Exchange icon
import WalletModal from "../Wallet/WalletModal"; // Import the WalletModal component
import bankpinfail from '../../assets/bankpinfail.mp3';
import { useNavigate } from 'react-router-dom'; // Update this import
import { TbLifebuoy } from "react-icons/tb";
import { usePlayerCount } from "../../hooks/usePlayerCount";
import { AiFillGift } from "react-icons/ai";
import Header from "./Header";


// Define the width of the drawer
const drawerWidth = 280;

// Define the styles for the opened drawer
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

// Define the styles for the closed drawer
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// Define the styles for the drawer header
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

// Define the props for the custom AppBar component
interface CustomAppBarProps extends AppBarProps {
  open?: boolean;
}

// Define the styles for the AppBar component
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<CustomAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


// Define the LeftSidebar component
const LeftSidebar = ({ onToggleSidebar, balanceString }: { onToggleSidebar: () => void; balanceString: string }) => {
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(!isMediumOrSmaller); // Sidebar is initially closed on medium or smaller screens
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [walletModalOpen, setWalletModalOpen] = useState(false); // State for Wallet Modal
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);



  // Define the styles for the Drawer component
  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {
        ...openedMixin(theme),
        display: 'flex',
        flexDirection: 'column',
      },
      ...(isMediumOrSmaller && { position: 'absolute' }),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {
        ...closedMixin(theme),
        display: 'flex',
        flexDirection: 'column',
      },
    }),
  }));

  // Function to handle closing the login modal
  const handleLoginModalClose = () => {
    setIsLoginModalOpen(false);
  };

  // Function to handle opening the drawer
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // Function to handle closing the drawer
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const handleWalletModalOpen = () => {
    setWalletModalOpen(true);
  };

  const handleWalletModalClose = () => {
    setWalletModalOpen(false);
  };

  const handleItemClick = () => {
    if (isMediumOrSmaller || open) {
      setOpen(false);
    }
  };

  const handleDiscordClick = async () => {
    window.open('https://discord.gg/QMNgDJVAuX', '_blank');
  };

  const handleRegisterModalClose = () => {
    setIsRegisterModalOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundImage: `url(${background})` }}>
        <Toolbar sx={{ minHeight: '64px', height: '64px', padding: '0 16px' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ marginRight: 0, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Header walletModalOpen={walletModalOpen} setWalletModalOpen={setWalletModalOpen} handleWalletModalOpen={handleWalletModalOpen} setIsLoginModalOpen={setIsLoginModalOpen} setIsRegisterModalOpen={setIsRegisterModalOpen} />
        </Toolbar>
      </AppBar>
      <LoginModal open={isLoginModalOpen} handleClose={handleLoginModalClose} />
      <LoginModal open={isRegisterModalOpen} handleClose={handleRegisterModalClose} isRegisterView={true} />
      <Drawer
        variant="permanent"
        open={open}
        onClose={handleDrawerClose}
        sx={{
          display: isMediumOrSmaller && !open ? "none" : "flex",
          "& .MuiDrawer-paper": {
            backgroundImage: `url(${background})`,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "#fff" }}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ backgroundImage: `url(${background_brighter})`, height: "4px" }} />
        <List sx={{
            flexGrow: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.05)', // subtle white overlay
            margin: '8px',
            borderRadius: '8px',
            '& .MuiListItem-root': {
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // slightly lighter on hover
              }
            }
          }}>
          {open && (
            <Typography
              variant="subtitle2"
              sx={{
                color: '#a0a0a0',
                padding: '12px 16px 8px',
                fontSize: '0.85rem',
                fontWeight: '700',
                textTransform: 'uppercase',
                letterSpacing: '0.05em',
                textShadow: '0 0 10px rgba(255,255,255,0.3)',
              }}
            >
              Classics
            </Typography>
          )}
          <Link to="/blackjack" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Blackjack" Icon={StyleIcon} />
          </Link>
          {/* <Link to="/free-bet-blackjack" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Free Bet Blackjack" Icon={TbCards} />
          </Link> */}
          <Link to="/ultimate-texas" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Ultimate Texas Hold'em" Icon={GiCardAceHearts} />
          </Link>
          <Link to="/roulette" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Roulette" Icon={GiCartwheel} />
          </Link>
          <Link to="/dice" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Dice" Icon={FaDice} />
          </Link>
          <Link to="/mines" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Mines" Icon={IoDiamond} />
          </Link>
          <Link to="/palmtower" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Palm Tower" Icon={GiWhiteTower} />
          </Link>
          <Link to="/plinko" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Plinko" Icon={FaHockeyPuck} />
          </Link>
          {open && (
            <Typography
              variant="subtitle2"
              sx={{
                color: '#a0a0a0',
                padding: '12px 16px 8px',
                fontSize: '0.85rem',
                fontWeight: '700',
                textTransform: 'uppercase',
                letterSpacing: '0.05em',
                textShadow: '0 0 10px rgba(255,255,255,0.3)',
                marginTop: '8px',
              }}
            >
              RuneScape
            </Typography>
          )}
          <Link to="/flower-poker" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Flower Poker" Icon={GiDaisy} />
          </Link>
          <Link to="/dharoks" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Dharoks Deathmatch" Icon={GiBatteredAxe} />
          </Link>
          <Link to="/duel-arena" style={{ textDecoration: "none" }} onClick={handleItemClick}>
            <LeftSideBarIcon open={open} name="Duel Arena" Icon={LuSwords} />
          </Link>
        </List>
        <Divider sx={{ backgroundImage: `url(${background_brighter})`, height: "4px" }} />
        <div className="mt-auto">
          <List sx={{
            flexGrow: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.05)', // subtle white overlay
            margin: '8px',
            borderRadius: '8px',
            '& .MuiListItem-root': {
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // slightly lighter on hover
              }
            }
          }}>
            <Link to="/exchanges" style={{ textDecoration: "none" }} onClick={handleItemClick}>
              <LeftSideBarIcon open={open} name="Exchanges" Icon={SwapHorizIcon} />
            </Link>
            <Link to="/rewards" style={{ textDecoration: "none" }} onClick={handleItemClick}>
              <LeftSideBarIcon open={open} name="Rewards" Icon={AiFillGift} />
            </Link>
            <Link to="/fairness" style={{ textDecoration: "none" }} onClick={handleItemClick}>
              <LeftSideBarIcon open={open} name="Fairness" Icon={GoLaw} />
            </Link>
            <div onClick={handleItemClick}>
              <LeftSideBarIcon open={open} name="Support" Icon={TbLifebuoy} />
            </div>
            <div onClick={handleDiscordClick}>
              <LeftSideBarIcon open={open} name="Discord" Icon={FaDiscord} />
            </div>
          </List>
        </div>
      </Drawer>
      <WalletModal open={walletModalOpen} onClose={handleWalletModalClose} />
    </Box>
  );
};

export default LeftSidebar;