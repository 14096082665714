import { useState, useEffect } from 'react';
import { supabase } from '../api/supabaseClient';
import { useAuth } from '../contexts/AuthContext';

interface FairnessData {
    game_id: number;
    game_type: string;
    server_seed: string;
    client_seed: string;
    nonce: string;
}

export function useFairness() {
    const [fairnessData, setFairnessData] = useState<FairnessData[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { session } = useAuth();

    useEffect(() => {
        // Function to fetch initial fairness data for the current user
        const fetchInitialFairnessData = async () => {
            setIsLoading(true);
            const { data, error } = await supabase
                .rpc('get_recent_game_seeds', { v_player_id: session?.user.id });

            if (error) {
                console.error('Error fetching fairness data:', error);
            } else {
                setFairnessData(data);
                setIsLoading(false);
            }
        };

        // Function to handle real-time updates
        const handleRealtimeUpdate = (payload: any) => {
            // This part depends on how you've set up real-time updates for your games
            // You might need to adjust this based on your specific implementation
            if (payload.new && payload.new.uuid === session?.user.id) {
                setFairnessData(currentData => {
                    const newGame = {
                        game_id: payload.new.id,
                        game_type: payload.new.game_type, // You might need to determine this based on the table
                        server_seed: payload.new.server_seed_hash,
                        client_seed: payload.new.client_seed,
                        nonce: payload.new.nonce
                    };
                    return [newGame, ...currentData.slice(0, 9)];
                });
            }
        };

        // Fetch initial data
        if (session?.user.id) {
            fetchInitialFairnessData();
        }else{
            setIsLoading(false);
        }

        // Set up real-time subscription
        const subscription = supabase
            .channel('game_fairness_changes')
            .on('INSERT', handleRealtimeUpdate)
            .subscribe();

        // Cleanup function
        return () => {
            subscription.unsubscribe();
        };
    }, [session?.user.id]);

    return { fairnessData, isLoading };
}